import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { CellContext, Row } from '@tanstack/react-table';

import OrderStatus from 'acceligent-shared/enums/orderStatus';
import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import ActionsCell from 'af-components/Table/Cells/ActionsCell';
import Breadcrumbs from 'af-components/Breadcrumbs';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import TableNew, { TableRef } from 'af-components/Table';
import { TableProps } from 'af-components/Table/types';
import TextCell from 'af-components/Table/Cells/TextCell';
import UpdatedByCell from 'af-components/Table/Cells/UpdatedByCell';

import CLIENT from 'af-routes/client';

import { RootState } from 'af-reducers';

import * as OrderActions from 'af-actions/orders';

import OrderTableVM from 'ab-viewModels/order/orderTable.viewModel';

import { isAllowed } from 'ab-utils/auth.util';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import * as SettingsKeys from 'af-constants/settingsKeys';

import styles from './styles.module.scss';

type Props = ConnectedProps<typeof connector> & CustomRouteComponentProps;

const PAGINATION_KEYS = {
	pageNumberKey: SettingsKeys.ORDER_PAGE_NUMBER(),
	pageSizeKey: SettingsKeys.ORDER_PAGE_SIZE(),
};

const OrdersTable: React.FC<Props> = (props) => {
	const { findForTable, history, companyName, location: { state: { orgAlias } }, deleteOrder, canManage } = props;

	const tableRef = React.useRef<TableRef<OrderTableVM>>(null);

	const fetchRows = React.useCallback(async (tableRequestModel: TableQuery): Promise<TableViewModel<OrderTableVM>> => {
		const { page, pageSize, filterByText, sortBy } = tableRequestModel;
		const result = await findForTable(
			new TableQuery({ page, pageSize, sortBy, filterByText })
		);
		return result;
	}, [findForTable]);

	const renderStatusCell = React.useCallback((cell: CellContext<OrderTableVM, string>) => {
		const status = cell.row.original.status;

		switch (status) {
			case OrderStatus.NEW: {
				return <TextCell className={styles['order-table__new-status']} value="New" />;
			}

			case OrderStatus.COMPLETED: {
				return <TextCell className={styles['order-table__completed-status']} value="Completed" />;
			}

			case OrderStatus.IN_PROGRESS: {
				return <TextCell className={styles['order-table__in-progress-status']} value="In Progress" />;
			}
		}
	}, []);

	const redirectToEditOrderPage = React.useCallback((row: Row<OrderTableVM>) => {
		history.push(CLIENT.COMPANY.ORDER.EDIT(orgAlias, companyName, `${row.original.id}`));
	}, [companyName, history, orgAlias]);

	const onDeleteOrderClick = React.useCallback(async (row: Row<OrderTableVM>) => {
		await deleteOrder(row.original.id);
	}, [deleteOrder]);

	const openPrintPreview = React.useCallback((orderId: number) => {
		const url = new URL(CLIENT.COMPANY.ORDER.EDIT(orgAlias, companyName, `${orderId}`), location.origin);
		url.searchParams.append('forPrint', 'true');

		window.open(url.toString(), '_blank');
	}, [companyName, orgAlias]);

	const resolveActionsButton = React.useCallback((_cell: CellContext<OrderTableVM, unknown>) => {
		const options = [
			{ onClick: () => openPrintPreview(_cell.row.original.id), label: 'Print' },
		];

		if (_cell.row.original.status !== OrderStatus.COMPLETED) {
			options.push({ onClick: () => redirectToEditOrderPage(_cell.row), label: 'Edit' });
		}

		if (canManage && _cell.row.original.status !== OrderStatus.COMPLETED) {
			options.push({
				onClick: async () => {
					await onDeleteOrderClick(_cell.row);
					tableRef.current?.refreshTable();
				}, label: 'Delete',
			});
		}

		return (
			<ActionsCell
				id="actions"
				isActionDropdown={true}
				labelKey="label"
				options={options}
				valueKey="label"
			/>
		);
	}, [canManage, redirectToEditOrderPage, openPrintPreview, onDeleteOrderClick]);

	const columns: TableProps<OrderTableVM>['columns'] = React.useMemo(() => [
		{
			id: 'status',
			accessor: 'status',
			header: 'Status',
			cell: renderStatusCell,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'requestedBy',
			accessor: 'requestedByFullName',
			header: 'Requested By',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={cell.getValue()} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'operator',
			accessor: 'operator',
			header: 'Operator',
			cell: (cell: CellContext<OrderTableVM, OrderTableVM['operator']>) => <TextCell value={cell.getValue()?.fullName ?? 'N/A'} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'deliveryMethod',
			accessor: 'deliveryMethod',
			header: 'Delivery Method',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={cell.getValue()} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'dateSubmitted',
			accessor: 'dateSubmitted',
			header: 'Date Submitted',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={cell.getValue()} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'dateNeeded',
			accessor: 'dateNeeded',
			header: 'Date Needed',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={cell.getValue()} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'location',
			accessor: 'locationNickname',
			header: 'Location',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={cell.getValue() ?? 'N/A'} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'updatedAt',
			accessor: 'updatedAt',
			header: 'Updated By',
			cell: (cell: CellContext<OrderTableVM, string>) =>
				<UpdatedByCell updatedAt={cell.row.original.updatedAt} updatedBy={cell.row.original.updatedBy} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'actions',
			isDisplayColumn: true,
			header: () => <EmptyCell isHeader />,
			cell: resolveActionsButton,
		},
	], [renderStatusCell, resolveActionsButton]);

	const redirectToCreateOrderPage = React.useCallback(async () => {
		history.push(CLIENT.COMPANY.ORDER.CREATE(orgAlias, companyName));
	}, [companyName, history, orgAlias]);

	const buttons = React.useMemo(() => [
		{ label: 'New Order', type: TableButtonType.PRIMARY, hasPermission: true, onClick: redirectToCreateOrderPage },
	], [redirectToCreateOrderPage]);

	return (
		<>
			<Breadcrumbs items={[{ label: 'Orders' }]} />
			<TableNew
				buttons={buttons}
				columns={columns}
				defaultPageSize={100}
				fetch={fetchRows}
				hasSearchInput={true}
				onRowClick={redirectToEditOrderPage}
				paginationKeys={PAGINATION_KEYS}
				ref={tableRef}
				searchTextKey={SettingsKeys.ORDER_SEARCH_TEXT()}
				tableName={TableNameEnum.ORDER}
			/>
		</>
	);
};

const mapStateToProps = (state: RootState) => {
	const { companyData, userData } = state.user;
	if (!companyData || !userData) {
		throw new Error('User not logged in');
	}
	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	return {
		companyName: companyData.name,
		canManage: isAllowed(PagePermissions.COMPANY.ORDER.MANAGE, permissions, isCompanyAdmin, role),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps());

function mapDispatchToProps() {
	return {
		findForTable: OrderActions.findForTable,
		deleteOrder: OrderActions.deleteOrder,
	};
}

export default connector(OrdersTable);
