import * as React from 'react';

import styles from './styles.module.scss';

interface Props {
	value: string;
	className?: string;
}

const TextCell: React.FC<Props> = ({ value, className }) => {
	const classNames = [styles['text-cell']];
	if (className) {
		classNames.push(className);
	}
	return <span className={classNames.join(' ')}>{value}</span>;
};

export default TextCell;
