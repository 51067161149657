import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, InjectedFormProps } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { Option } from 'react-select/src/filters';

import Carrier from 'acceligent-shared/enums/orderCarrier';

import * as RepairLocationActions from 'af-actions/repairLocation';

import Input from 'af-fields/Input';
import Textarea from 'af-fields/Textarea';
import Select from 'af-fields/SelectField';
import Dropdown from 'af-fields/Dropdown';
import Checkbox from 'af-fields/Checkbox';

import RepairLocationVM from 'ab-viewModels/repairLocation/repairLocation.viewModel';
import { EmployeeOptionVM } from 'ab-viewModels/employee/option.viewModel';

import FormModel from './formModel';
import { isValidTextInput } from 'acceligent-shared/utils/text';

type OwnProps = {
	disabled: boolean;
	change: InjectedFormProps<FormModel>['change'];
	externalRepair: boolean;
	employeesForShipping: EmployeeOptionVM[];
};

type Props = OwnProps & ConnectedProps<typeof connector>;

const formatRepairLocationOptionLabel = (option: RepairLocationVM) => <div>{option.name}</div>;
const formatEmployeeOptionLabel = (option: EmployeeOptionVM) => <div>{option.firstName} {option.lastName}</div>;
const filterSelects = (textA: string, textB: string) => textA.toLowerCase().includes(textB.toLowerCase());
const filterEmployee = ({ data }: Option, text: string) => filterSelects(`${data.firstName} ${data.lastName} ${data.uniqueId}`, text);
const getSelectOptionValue = (option: { id: number; }) => option.id;
const getSelectAccountOptionValue = (option: { accountId: number; }) => option.accountId;

const AccountSelect = Select as unknown as new () => Select<{ id: number; serialNumber: string; toolType: string; }>;

const ExternalRepair = (props: Props) => {
	const {
		employeesForShipping,
		externalRepair,
		change,
		createRepairLocation,
		findRepairLocations,
		disabled,
	} = props;

	const [loading, setLoading] = React.useState(false);
	const [repairLocationOptions, setRepairLocationOptions] = React.useState<RepairLocationVM[]>([]);

	React.useEffect(() => {
		setLoading(true);
	}, []);

	React.useEffect(() => {
		if (loading) {
			const loadStuff = async () => {
				const [
					repairLocations,
				] = await Promise.all([
					findRepairLocations(),
				]);
				setRepairLocationOptions(repairLocations);

				setLoading(false);
			};
			loadStuff();
		}
	}, [loading, findRepairLocations]);

	const onRepairLocationCreate = React.useCallback(async (name: string) => {
		const newToolType = await createRepairLocation({ name });
		setRepairLocationOptions([...repairLocationOptions, newToolType]);
		change('repairLocation', newToolType);
	}, [change, createRepairLocation, repairLocationOptions]);

	const getNewRepairLocation = React.useCallback((value: string, label: string) => {
		return { id: +value, name: label, __isNew__: true };
	}, []);

	const isNewRepairLocationValid = React.useCallback((text: string) => {
		return isValidTextInput(text) && !repairLocationOptions.some((_option) => _option.name === text);
	}, [repairLocationOptions]);

	const formatCreateLabel = React.useCallback((inputValue: string) => {
		return (
			<div>
				<span className="react-select-dropdown__create-label">
					<span className="icon-plus" />
					Create New:
				</span>
				<span>{inputValue}</span>
			</div>
		);
	}, []);

	const filterRepairLocation = React.useCallback(async (option: Option, searchText: string) => {
		if (!option) {
			return false;
		}

		const name = option.data.name.toLowerCase();
		return name.includes(searchText.toLowerCase());
	}, []);

	const clearRepairLocation = React.useCallback(() => {
		change('repairLocation', null);
	}, [change]);

	const CARRIER_OPTIONS = Object.values(Carrier).map((_carrier, _index) => ({
		id: _index,
		label: _carrier,
	}));

	return (
		<>
			<Row>
				<Col md={24}>
					<Field
						component={Checkbox}
						isDisabled={disabled}
						label="External Repair"
						name="isExternal"
					/>
				</Col>
			</Row>
			{externalRepair && (

				<Row>
					<Col md={6}>
						<Field
							component={AccountSelect}
							filterOption={filterEmployee}
							formatOptionLabel={formatEmployeeOptionLabel}
							getOptionValue={getSelectAccountOptionValue}
							isDisabled={disabled}
							label="Who is shipping it?"
							name="shippedBy"
							options={employeesForShipping}
							placeholder="Select"
						/>
						<Field
							component={Dropdown}
							disabled={disabled}
							label="Carrier *"
							labelKey="label"
							name="carrier"
							options={CARRIER_OPTIONS}
							placeholder="Select Carrier"
							valueKey="label"
							withCaret={true}
						/>
					</Col>
					<Col md={6}>
						<Field
							allowNew={true}
							component={Select}
							filterOption={filterRepairLocation}
							formatCreateLabel={formatCreateLabel}
							formatOptionLabel={formatRepairLocationOptionLabel}
							getNewOptionData={getNewRepairLocation}
							getOptionValue={getSelectOptionValue}
							isDisabled={disabled}
							isValidNewOption={isNewRepairLocationValid}
							label="Where is it being repaired? *"
							name="repairLocation"
							onClear={clearRepairLocation}
							onCreateNew={onRepairLocationCreate}
							onSearch={filterRepairLocation}
							options={repairLocationOptions}
							placeholder="Select"
						/>
						<Field
							component={Input}
							disabled={disabled}
							label="Tracking number"
							name="trackingNumber"
							type="text"
						/>
					</Col>
					<Col md={12}>
						<Field
							component={Textarea}
							controlCursor={true}
							disabled={disabled}
							label="External Comment"
							name="externalComment"
							rows={6}
						/>
					</Col>
				</Row>
			)
			}
		</>
	);
};

function mapDispatchToProps() {
	return {
		findRepairLocations: RepairLocationActions.findList,
		createRepairLocation: RepairLocationActions.create,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(ExternalRepair);
