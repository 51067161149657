import PurchaseOrderItemCompletion from 'acceligent-shared/enums/purchaseOrderItemCompletion';
import PurchaseOrderStatus from 'acceligent-shared/enums/purchaseOrderStatus';
import PurchaseOrderTruckPosition from 'acceligent-shared/enums/purchaseOrderTruckPosition';

import { PurchaseOrderVM } from 'ab-viewModels/purchaseOrder/purchaseOrder.viewModel';
import { CreateOrUpdatePurchaseOrderRM } from 'ab-requestModels/purchaseOrder/createOrUpdatePurchaseOrder.requestModel';

export type PurchaseOrderItemFormModel = {
	id: number | string;
	itemId: Nullable<number>;
	quantity: number;
	itemNumber: Nullable<string>;
	itemName: string;
	priceEach: number;
	extendedPrice: number;
	itemsReceived: PurchaseOrderItemCompletion;
	itemsInvoiced: PurchaseOrderItemCompletion;
};

export class PurchaseOrderFormModel {
	id?: number;
	status: PurchaseOrderStatus;
	orderNumber: string;
	index: number;
	dateSubmitted: string;
	shipVia: Nullable<string>;
	shipTo: Nullable<string>;
	vendorId: number;
	purchaseOrderItems: PurchaseOrderItemFormModel[];
	frontOrRearOfTruck: PurchaseOrderTruckPosition;
	equipmentId: Nullable<number>;
	workRequestId: Nullable<number>;
	itemCategoryId: Nullable<number>;
	requestedById: Nullable<number>;
	submittedById: Nullable<number>;
	locationId: Nullable<number>;
	note: Nullable<string>;

	static fromViewModel = (po: PurchaseOrderVM): PurchaseOrderFormModel => {
		return {
			id: po.id,
			status: po.status,
			orderNumber: po.orderNumber,
			index: po.index,
			dateSubmitted: po.dateSubmitted,
			shipVia: po.shipVia,
			shipTo: po.shipTo,
			vendorId: po.vendorId,
			purchaseOrderItems: (po.purchaseOrderItems ?? []).map((item) => ({
				id: item.id,
				itemId: item.itemId,
				quantity: item.quantity,
				extendedPrice: item.quantity * item.priceEach,
				itemNumber: item.itemNumber,
				itemName: item.itemName,
				priceEach: item.priceEach,
				itemsReceived: item.itemsReceived,
				itemsInvoiced: item.itemsInvoiced,
			})),
			equipmentId: po.equipmentId,
			workRequestId: po.workRequestId,
			itemCategoryId: po.itemCategoryId,
			requestedById: po.requestedById,
			submittedById: po.submittedById,
			locationId: po.locationId,
			frontOrRearOfTruck: po.frontOrRearOfTruck,
			note: po.note,
		};
	};

	static toRM = (form: PurchaseOrderFormModel): CreateOrUpdatePurchaseOrderRM => {
		return {
			id: form.id,
			equipmentId: form.equipmentId,
			frontOrRearOfTruck: form.frontOrRearOfTruck,
			index: form.index,
			itemCategoryId: form.itemCategoryId,
			locationId: form.locationId,
			note: form.note,
			orderNumber: form.orderNumber,
			purchaseOrderItems: (form.purchaseOrderItems ?? []).map((_purchaseItem) => ({ ..._purchaseItem, id: typeof _purchaseItem.id === 'number' ? _purchaseItem.id : undefined })),
			shipTo: form.shipTo,
			shipVia: form.shipVia,
			status: form.status,
			vendorId: form.vendorId,
			workRequestId: form.workRequestId,
			requestedById: form.requestedById,
		};
	};
}
