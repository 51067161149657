import { Dispatch, AnyAction } from 'redux';

import * as FieldReportSyncVMs from 'acceligent-shared/dtos/socket/view/newFieldReport/sync';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { SignatureFieldVM } from 'acceligent-shared/utils/fieldReport';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';
import * as FieldReportUtils from 'af-utils/fieldReport.util';

import * as FormUtil from 'ab-utils/form.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import * as FieldReportRequestModels from 'ab-requestModels/fieldReport/fieldReport.requestModel';
import { FieldReportBlockFieldUploadRM } from 'ab-requestModels/fieldReport/fieldReportSubmit.requestModel';
import * as SignatureRequestModel from 'ab-requestModels/signature.requestModel';

import WorkOrderForReportsTableVM from 'ab-viewModels/workOrder/workOrderForReportsTable.viewModel';
import CondensedTableVM from 'ab-viewModels/workOrder/workOrderForReportsCondensedTable';
import FieldReportCreateVM from 'ab-viewModels/fieldReport/create.viewModel';
import NewFieldReportVM from 'ab-viewModels/fieldReport/fieldReport.viewModel';
import SocketConnectionCount from 'ab-viewModels/socketConnectionCount.viewModel';
import FieldReportBlockFieldImageVM from 'ab-viewModels/fieldReport/fieldReportBlockFieldImage.viewModel';
import ReportListForBulkSendItemVM from 'ab-viewModels/report/listForBulkSendItem.viewModel';
import WorkSummaryDetailVM from 'ab-viewModels/fieldReport/workSummaryDetails.viewModel';
import WorkSummaryVM from 'ab-viewModels/fieldReport/workSummary.viewModel';

import { LockFieldReportBlockVM } from 'ab-socketModels/viewModels/fieldReport/lockFieldReportBlock.viewModel';

import UserTypeFilter from 'ab-enums/userTypeFilter.enum';

import * as fieldReportActionCreators from 'af-actions/fieldReport/fieldReport.actionCreators';

import { GetRootState } from 'af-reducers';

// #region create

export function createFieldReport(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = { workOrderId } as FieldReportRequestModels.CreateRM;
			return await http.post<FieldReportCreateVM>(API.V1.FIELD_REPORT.CREATE_FIELD_REPORT, data);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #endregion

// #region read

export function findById(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			dispatch(fieldReportActionCreators.SET_FIELD_REPORT_NEW(undefined));
			const report = await http.get<NewFieldReportVM>(API.V1.FIELD_REPORT.FIND_BY_ID(id));
			dispatch(fieldReportActionCreators.SET_FIELD_REPORT_NEW(report));
			return report;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

/** TODO: create Report actions and move this there */
export function findAllForReportsTable(tableRequestModel: TableQuery, startDate: Date, endDate: Date, assignmentFilter: UserTypeFilter) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			const startDateString = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
			const endDateString = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY);

			return await http.get<TableViewModel<WorkOrderForReportsTableVM>>(
				API.V1.WORK_ORDER.LIST_REPORTS(data, startDateString, endDateString, assignmentFilter)
			);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

/** TODO: create Report actions and move this there */
export function findReportsListForBulkSend(workRequestId: number, startDate: Date, endDate: Date) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const startDateString = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
			const endDateString = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY);

			return await http.get<ReportListForBulkSendItemVM[]>(
				API.V1.JOB.REPORTS_LIST_FOR_BULK_SEND(workRequestId, startDateString, endDateString)
			);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForReportsCondensedTable(
	startDate: Date,
	endDate: Date,
	page: number,
	limit: number,
	filterText: string,
	sortBy: keyof CondensedTableVM,
	sortAscending: boolean
) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {

			return await http.get<TableViewModel<CondensedTableVM>>(
				API.V1.WORK_ORDER.CONDENSED_LIST_REPORTS(
					TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY),
					TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY),
					page,
					limit,
					filterText,
					sortBy,
					sortAscending
				)
			);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findByIdForReportsCondensedTable(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<CondensedTableVM>(API.V1.WORK_ORDER.FIND_BY_ID_FOR_CONDENSED_LIST(workOrderId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #endregion

// #region update

export function updateFieldReportBlockCompletedStatus(id: number, fieldReportBlockId: number, completed: boolean) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put(API.V1.FIELD_REPORT.UPDATE_BLOCK_COMPLETED_STATUS(id, fieldReportBlockId), { completed });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editFieldReportTypes(id: number, reportTypes: FieldReportRequestModels.EditFieldReportTypesRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put(API.V1.FIELD_REPORT.EDIT_REPORT_TYPES(id), reportTypes);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editFieldReportValue(id: number, fieldReportTypeId: number, data: FieldReportRequestModels.FieldReportBlockFieldRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.FIELD_REPORT.UPDATE_FIELD_VALUE(id, fieldReportTypeId), data);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function addInstance(fieldReportId: number, fieldReportTypeId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post<void>(API.V1.FIELD_REPORT.ADD_INSTANCE(fieldReportId, fieldReportTypeId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function addSegment(id: number, fieldReportTypeId: number, instanceIndex: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post<void>(API.V1.FIELD_REPORT.ADD_SEGMENT(id, fieldReportTypeId, instanceIndex));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function addRepeatableFields(id: number, fieldReportBlockId: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.FIELD_REPORT.REPEATABLE_FIELDS(id, fieldReportBlockId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function uploadBlockFieldSignature(
	form: SignatureRequestModel.SignatureRequestModel,
	fieldReportId: number
) {
	return async (dispatch: Dispatch<fieldReportActionCreators.FieldReportAction>, { redirectTo }) => {
		const action = async () => {
			return await http.post<SignatureFieldVM>(API.V1.FIELD_REPORT.FIELD_SIGNATURE(fieldReportId), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteBlockFieldSignature(
	form: SignatureRequestModel.RemoveSignatureRequestModel,
	fieldReportId: number
) {
	return async (dispatch: Dispatch<fieldReportActionCreators.FieldReportAction>, { redirectTo }) => {
		const action = async () => {
			return await http.delete<void>(API.V1.FIELD_REPORT.FIELD_SIGNATURE(fieldReportId), form);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function uploadBlockFieldImage(
	form: FieldReportBlockFieldUploadRM,
	fieldReportId: number,
	fieldReportTypeId: number,
	fieldReportBlockId: number,
	fieldReportBlockFieldId: number
) {
	return async (dispatch: Dispatch<fieldReportActionCreators.FieldReportAction>, { redirectTo }) => {
		const action = async () => {
			const options = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, 'imageUrl');
			return await http.post<FieldReportBlockFieldImageVM>(
				API.V1.FIELD_REPORT.UPLOAD_FIELD_IMAGE(fieldReportId, fieldReportTypeId, fieldReportBlockId, fieldReportBlockFieldId),
				fd,
				options
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #region delete

export function deleteFieldReport(id: number, workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.FIELD_REPORT.DELETE_REPORT_BY_ID(id), { workOrderId });
			FieldReportUtils.deleteFieldReport(id);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function removeSegment(
	id: number,
	fieldReportTypeId: number,
	instanceIndex: number,
	segmentIndex: number
) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		// starts the loading state
		dispatch(fieldReportActionCreators.SET_SEGMENT_DELETING({ instanceIndex, segmentIndex }));

		const action = async () => {
			await http.delete(API.V1.FIELD_REPORT.REMOVE_SEGMENT(id, fieldReportTypeId), { instanceIndex, segmentIndex });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function removeRepeatableFields(id: number, fieldReportBlockId: string, index: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.FIELD_REPORT.REPEATABLE_FIELDS(id, fieldReportBlockId), { index });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findWorkSummaryDetails(fieldReportId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<WorkSummaryDetailVM[]>(API.V1.FIELD_REPORT.FIND_WORK_SUMMARY_DETAILS(fieldReportId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findWorkSummary(fieldReportId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<WorkSummaryVM[]>(API.V1.FIELD_REPORT.FIND_WORK_SUMMARY(fieldReportId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function checkIfBillableWorkExists(fieldReportId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<boolean>(API.V1.FIELD_REPORT.CHECK_IF_BILLABLE_WORK_EXISTS(fieldReportId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function resetJobWorkSummary(fieldReportId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.put<void>(API.V1.FIELD_REPORT.RESET_JOB_WORK_SUMMARY(fieldReportId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #endregion

// #region dispatch only

export function setLocks(locks: Record<string, LockFieldReportBlockVM>) {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch(fieldReportActionCreators.SET_FIELD_REPORT_BLOCK_LOCK(locks));
	};
}

export function removeLock(fieldReportBlockId: string) {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch(fieldReportActionCreators.REMOVE_FIELD_REPORT_BLOCK_LOCK(fieldReportBlockId));
	};
}

export function syncBlockValues(syncData: FieldReportSyncVMs.SyncBlock) {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch(fieldReportActionCreators.SET_FIELD_REPORT_BLOCK_VALUE_NEW(syncData));
	};
}

export function syncAddInstance(syncData: FieldReportSyncVMs.SyncAddInstance) {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch(fieldReportActionCreators.ADD_INSTANCE(syncData));
	};
}

export function syncAddSegment(syncData: FieldReportSyncVMs.SyncAddSegment) {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch(fieldReportActionCreators.ADD_SEGMENT(syncData));
	};
}

export function syncRemoveInstance(syncData: FieldReportSyncVMs.SyncRemoveInstance) {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch(fieldReportActionCreators.REMOVE_INSTANCE(syncData));
	};
}

export function syncRemoveSegment(syncData: FieldReportSyncVMs.SyncRemoveSegment) {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch(fieldReportActionCreators.REMOVE_SEGMENT(syncData));
	};
}

export function setConnectionCount(connectionCount: SocketConnectionCount) {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fieldReportActionCreators.SET_CONNECTION_COUNT(connectionCount));
	};
}

export function syncFieldReportTypes(fieldReportTypesForFieldReport: FieldReportSyncVMs.SyncFieldReportTypes) {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fieldReportActionCreators.SET_FIELD_REPORT_TYPES(fieldReportTypesForFieldReport));
	};
}

export function syncFieldReportBlockCompletedStatus(event: FieldReportSyncVMs.SyncBlockCompletedStatus) {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch(fieldReportActionCreators.SET_FIELD_REPORT_BLOCK_COMPLETED(event));
	};
}

// #endregion
