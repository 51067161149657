import * as React from 'react';
import { compose } from 'redux';
import { Field, FieldArray, InjectedFormProps, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import { ConnectedProps, connect } from 'react-redux';
import { CustomRouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import * as html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import { EquipmentListItemVM } from 'acceligent-shared/dtos/web/view/timeSplitEquipment/timeSplitEquipment';
import OrderStatus from 'acceligent-shared/enums/orderStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import OrderDeliveryMethod from 'acceligent-shared/enums/orderDeliveryMethod';

import { getUserName } from 'acceligent-shared/utils/user';
import { formatDate } from 'acceligent-shared/utils/time';

import * as EmployeeAction from 'af-actions/employee';
import * as EquipmentActions from 'af-actions/equipment';
import * as ItemActions from 'af-actions/item';
import * as JobActions from 'af-actions/jobs';
import * as LocationActions from 'af-actions/location';
import * as OrderActions from 'af-actions/orders';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import { dollarFormatter } from 'af-utils/format.util';
import { isAllowed } from 'ab-utils/auth.util';

import { ORDER_FORM } from 'af-constants/reduxForms';

import CLIENT from 'af-routes/client';

import { RootState } from 'af-reducers';

import { EmployeeOptionVM } from 'ab-viewModels/employee/option.viewModel';
import JobOrderFormVM from 'ab-viewModels/workRequest/jobOrderForm.viewModel';

import ColorSquare from 'af-components/ColorSquare';
import TextHighlight from 'af-components/TextHighlight';
import ConfirmationModal from 'af-components/ConfirmationModal';
import Form from 'af-components/Form';
import Breadcrumbs from 'af-components/Breadcrumbs';
import formStyles from 'af-components/Form/styles.module.scss';

import DateInput from 'af-fields/DateInput';
import Dropdown from 'af-fields/Dropdown';
import Textarea from 'af-fields/Textarea';

import { useLazyLoad } from 'af-utils/react.util';

import OrderItems, { OwnProps as OrderItemsProps } from './OrderItems';
import OrderUpsertFM, { OrderItemFM } from './formModel';
import styles from './styles.module.scss';
import DeliveryMethodFields from './DeliveryMethodFields';

type PathParams = {
	orderId?: string;
};

type OwnProps = CustomRouteComponentProps<PathParams>;
type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<OrderUpsertFM>;

const resolveBreadcrumbs = (label: string, orgAlias: string, companyName: string) => {
	const items: { label: string; url?: string; }[] =
		[
			{
				label: 'Orders',
				url: CLIENT.COMPANY.ORDER.TABLE(orgAlias, companyName),
			},
			{
				label,
			},
		];
	return items;
};

const renderOperatorMenuItem = (option: EmployeeOptionVM, searchText: string) => {
	return (
		<TextHighlight searchText={searchText} text={`${option.firstName} ${option.lastName}`} />
	);
};

const renderEquipmentOptionItem = (option: EquipmentListItemVM, searchText: string) => {
	return (
		<div className={styles['order-form__equipment']} key={option.id}>
			{option.color && <ColorSquare color={option.color} />}
			<TextHighlight searchText={searchText} text={option.code} />
			{option.specification &&
				<span className="resource-lookup__resource-info">
					(<TextHighlight searchText={searchText} text={option.specification} />)
				</span>}
		</div>
	);
};

const renderJobOptionItem = (option: JobOrderFormVM, searchText: string) => {
	return (
		<div key={option.id}>
			<div>
				<TextHighlight searchText={searchText} text={option.jobCode} />
			</div>
			<div>
				{option.title ? (
					<small className={styles['order-form__menu-option__sub-text']}>
						<TextHighlight searchText={searchText} text={option.title} />
					</small>
				) : null}
			</div>
		</div>
	);
};

const renderSelectedEquipment = (option: EquipmentListItemVM) => {
	return (
		<div className={styles['order-form__equipment']}>
			{option.color && <ColorSquare color={option.color} />}
			{option.code}
			{option.specification && ` (${option.specification}) `}
		</div>
	);
};

const renderSelectedJob = (option: JobOrderFormVM) => {
	return (
		<>
			{option.jobCode}
		</>
	);
};

const OrderStatusLabel: Record<OrderStatus, { label: string; classModifier: string; }> = {
	[OrderStatus.NEW]: { label: 'New', classModifier: styles['order-header__status--new'] },
	[OrderStatus.IN_PROGRESS]: { label: 'In Progress', classModifier: styles['order-header__status--in-progress'] },
	[OrderStatus.COMPLETED]: { label: 'Completed', classModifier: styles['order-header__status--completed'] },
};

const renderSelectedStatus = (option: OrderStatus) => {

	const status = OrderStatusLabel[option] ?? OrderStatusLabel.NEW;
	return (
		<div className={`${styles['order-header__status']} ${status.classModifier}`}>
			{status.label}
		</div>
	);
};

const leavePageModalBody = <>
	You are about to leave this page without saving or submitting. <br />
	Changes that you made will not be saved.
</>;

const OrderUpsertForm: React.FC<Props> = (props) => {
	const {
		match: { params: { orderId } },
		location: { state: { orgAlias, originUrl }, search },
		findForEdit,
		initialize,
		initialized,
		companyName,
		findAllEquipmentForCompany,
		findAllJobsForCompany,
		change,
		formDeliveryMethod,
		findAllLocationsForCompany,
		findAllFieldWorkers,
		findItemDepartments,
		form,
		createOrder,
		editOrder,
		valid,
		errors,
		history,
		formData,
		canManage,
		status,
		dirty,
	} = props;
	const { options: equipmentOptions, lazyLoad: lazyLoadEquipment } = useLazyLoad(findAllEquipmentForCompany);
	const { options: jobOptions, lazyLoad: lazyLoadJobs } = useLazyLoad(findAllJobsForCompany);
	const { options: accountOptions, lazyLoad: lazyLoadAccounts } = useLazyLoad(findAllFieldWorkers);
	const [total, setTotal] = React.useState(0);
	const [order, setOrder] = React.useState<Nullable<OrderUpsertFM>>(null);
	const [isLeavePageModalOpen, setIsLeavePageModalOpen] = React.useState(false);

	const queryParams = new URLSearchParams(search);
	const forPrint = queryParams.get('forPrint') === 'true';

	const isInEditMode = !!orderId;

	const captureAndExportPDF = React.useCallback(async () => {

		// Get the sticky header element
		const formHeader = document.getElementById('form-header');

		let originalPosition;
		if (formHeader) {
			// Store the original position style
			originalPosition = formHeader.style.position;

			// Temporarily disable sticky position
			formHeader.style.position = 'static';
		}

		// Get the scrollable div
		const scrollableDiv = document.getElementById('scrollableDiv');

		if (scrollableDiv) {
			// Scroll the div to the top
			scrollableDiv.scrollTop = 0;

			// Wait for the scroll to complete
			await new Promise((resolve) => setTimeout(resolve, 500));
		}

		// Find all elements with the class 'form__section'
		const sections = document.querySelectorAll(`.${formStyles.form__section}`);
		const printButton = document.getElementById('print-button');

		// Temporarily remove the box shadow since it causes problems in exported picture
		sections.forEach((section) => {
			(section as HTMLElement).style.boxShadow = 'none';
		});

		printButton!.style.display = 'none';

		// Capture the page
		await document.fonts.ready;
		const element = document.getElementById('form');

		const canvas = await html2canvas(element!, {
			scrollX: -window.scrollX,
			scrollY: -window.scrollY,
			windowWidth: 1240,
			windowHeight: document.documentElement.offsetHeight,
		});

		// Convert canvas to an image
		const imgData = canvas.toDataURL('image/png');

		// Create a PDF document
		const pdf = new jsPDF('p', 'mm', 'a4'); // 'p' for portrait, 'mm' for millimeters, 'a4' for page size

		// Calculate the width and height of the image in the PDF
		const imgProps = pdf.getImageProperties(imgData);
		const pdfWidth = pdf.internal.pageSize.getWidth();
		const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

		let position = 0;
		const pageHeight = pdf.internal.pageSize.getHeight();

		// Loop to add image to PDF in chunks
		while (position < pdfHeight) {
			// Add the image to the PDF
			pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);

			position -= pageHeight; // Move position to the next page
			if (position < -pdfHeight) break;

			// Add new page
			pdf.addPage();
		}

		// Save the PDF
		pdf.save(`Order_${orderId}-${formatDate(new Date(), TimeFormat.FULL_DATE)}.pdf`);

		// Restore the original state
		if (formHeader) {
			formHeader.style.position = originalPosition || 'sticky';
		}

		printButton!.style.display = 'block';
	}, [orderId]);

	const fetchAndInitializeForEdit = React.useCallback(async () => {
		if (initialized) {
			return;
		}
		if (isInEditMode) {
			const fetchedOrder = await findForEdit(+orderId);
			const orderFM = new OrderUpsertFM(fetchedOrder);
			setOrder(orderFM);
			initialize(orderFM);
		} else {
			initialize({ deliveryMethod: OrderDeliveryMethod.DELIVERY });
		}
	}, [findForEdit, initialize, initialized, isInEditMode, orderId]);

	const onEquipmentClear = React.useCallback(() => {
		change(OrderUpsertFM.getAttributeName('equipment'), null);
		change(OrderUpsertFM.getAttributeName('equipmentId'), null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onOperatorClear = React.useCallback(() => {
		change(OrderUpsertFM.getAttributeName('operator'), null);
		change(OrderUpsertFM.getAttributeName('operatorId'), null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onJobClear = React.useCallback(() => {
		change(OrderUpsertFM.getAttributeName('job'), null);
		change(OrderUpsertFM.getAttributeName('jobId'), null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submit = React.useCallback(async (_form: OrderUpsertFM) => {
		const rm = OrderUpsertFM.toRM(_form);
		if (isInEditMode) {
			await editOrder(+orderId, rm);
		} else {
			await createOrder(rm);
		}
		history.push(CLIENT.COMPANY.ORDER.TABLE(orgAlias, companyName));
	}, [companyName, createOrder, editOrder, history, isInEditMode, orderId, orgAlias]);

	const onCreate = React.useCallback(() => {
		const _form = { ...formData, status: OrderStatus.NEW };
		submit(_form);
	}, [formData, submit]);

	const onSave = React.useCallback(() => {
		submit(formData);
	}, [formData, submit]);

	const onSubmit = React.useCallback(() => {
		const _form = { ...formData, status: OrderStatus.IN_PROGRESS };
		submit(_form);
	}, [formData, submit]);

	const onComplete = React.useCallback(() => {
		const _form = { ...formData, status: OrderStatus.COMPLETED };
		submit(_form);
	}, [formData, submit]);

	const calculateAndSetTotal = React.useCallback((orderItems: OrderItemFM[] = []) => {
		const newTotal = orderItems.reduce((_acc, _currOI) => {
			if (_currOI.excludeFromTotalPrice) {
				return _acc;
			}

			const currentPrice = _currOI.quantity * (_currOI?.itemDepartment?.price ?? 0);
			return _acc + currentPrice;
		}, 0);

		setTotal(newTotal);
	}, []);

	React.useEffect(() => {
		fetchAndInitializeForEdit();
	}, [fetchAndInitializeForEdit]);

	const onBack = React.useCallback(() => {
		history.push(originUrl || CLIENT.COMPANY.ORDER.TABLE(orgAlias, companyName));
	}, [companyName, history, orgAlias, originUrl]);

	const closeLeavePageModal = React.useCallback(() => {
		setIsLeavePageModalOpen(false);
	}, [setIsLeavePageModalOpen]);

	const openLeavePageModal = React.useCallback(() => {
		setIsLeavePageModalOpen(true);
	}, [setIsLeavePageModalOpen]);

	const close = React.useCallback(() => {
		if (dirty) {
			openLeavePageModal();
		} else {
			onBack();
		}
	}, [dirty, openLeavePageModal, onBack]);

	const leavePageModalFooter = React.useCallback(() => {
		return (
			<>
				<Button onClick={closeLeavePageModal} variant="info">
					Stay
				</Button>
				<Button onClick={onBack} variant="danger">
					Leave
				</Button>
			</>
		);
	}, [closeLeavePageModal, onBack]);

	const hasItemErrors = React.useMemo(() => !!Object.keys(errors?.items ?? []).length, [errors]);

	const calendarSettings = React.useMemo(() => ({ minDate: order?.dateSubmitted ? new Date(order.dateSubmitted) : new Date() }), [order]);

	const operatorName = React.useMemo(() => {
		return formData?.operator ? getUserName(formData?.operator) : 'N/A';
	}, [formData?.operator]);

	const equipmentOption = React.useMemo(() => {
		return formData?.equipment;
	}, [formData]);

	const dateNeeded = React.useMemo(() => {
		return formData?.dateNeeded;
	}, [formData]);

	const job = React.useMemo(() => {
		return formData?.job;
	}, [formData?.job]);

	const deliveryNote = React.useMemo(() => {
		return formData?.deliveryNote ?? '-';
	}, [formData?.deliveryNote]);

	const notes = React.useMemo(() => {
		return formData?.notes ?? '-';
	}, [formData?.notes]);

	if (isInEditMode && !order) {
		return <Breadcrumbs items={resolveBreadcrumbs('Loading', orgAlias, companyName)} />;
	}

	const breadcrumbLabel = isInEditMode ? forPrint ? 'Preview' : 'Edit' : 'New Order';

	const disabled = isInEditMode && ((status !== OrderStatus.NEW && !canManage) || status === OrderStatus.COMPLETED) || forPrint;

	const showCompleteButton = status === OrderStatus.IN_PROGRESS && canManage && !forPrint;
	const showCreateButton = !isInEditMode && !forPrint;
	const showSubmitButton = canManage && (!isInEditMode || status === OrderStatus.NEW) && !forPrint;
	const showSaveButton = isInEditMode && (status === OrderStatus.NEW || (status === OrderStatus.IN_PROGRESS && canManage)) && !forPrint;

	return (
		<>
			<Breadcrumbs items={resolveBreadcrumbs(breadcrumbLabel, orgAlias, companyName)} />
			<Form id="form">
				<Form.Header id="form-header" sticky={true}>
					<div className={styles['order-header__left']}>
						<span className={styles['order-header__cost']}>
							{`Cost for Order: ${dollarFormatter.format(total)}`}
						</span>
						<div className={styles['order-header__status-label']}>
							<span>Order Status</span>
							{renderSelectedStatus(status)}
						</div>
					</div>
					<div className={styles['order-header__right']}>
						{
							forPrint &&
							<Button id="print-button" onClick={captureAndExportPDF}>
								Print
							</Button>
						}
						{
							!forPrint &&
							<Button onClick={close} variant="info">
								Back
							</Button>
						}

						{showCreateButton && (
							<Button disabled={!valid || hasItemErrors} onClick={onCreate} variant="primary">Create</Button>
						)}
						{showSaveButton && (
							<Button disabled={!valid || hasItemErrors} onClick={onSave} variant="primary">Save</Button>
						)}
						{showSubmitButton && (
							<Button disabled={!valid || hasItemErrors} onClick={onSubmit} variant="primary">Submit</Button>
						)}
						{showCompleteButton && (
							<Button disabled={!valid || hasItemErrors} onClick={onComplete} variant="primary">Complete</Button>
						)}
					</div>
				</Form.Header>
				<Form.Section title="Order Details">
					<Row className={styles['order-row']}>
						<Col md={12}>
							{!disabled &&
								<Field
									component={Dropdown}
									filterable={true}
									filterBy={['firstName', 'lastName']}
									id="operator_select"
									label="Operator"
									name={OrderUpsertFM.getAttributeName('operatorId')}
									onClear={onOperatorClear}
									onLazyLoad={lazyLoadAccounts}
									options={accountOptions}
									placeholder="Select Operator"
									propName={OrderUpsertFM.getAttributeName('operator')}
									renderMenuItem={renderOperatorMenuItem}
									valueKey="accountId"
									withCaret={true}
								/>
							}
							{
								disabled &&
								<>
									<div>Operator</div>
									<div className={styles['field-value']}>{operatorName}</div>
								</>
							}
							{
								!disabled &&
								<Field
									component={Dropdown}
									filterable={true}
									filterBy={['code']}
									id="equipment_select"
									label="Equipment"
									name="equipmentId"
									onClear={onEquipmentClear}
									onLazyLoad={lazyLoadEquipment}
									options={equipmentOptions.list}
									placeholder="Select Equipment"
									propName={OrderUpsertFM.getAttributeName('equipment')}
									renderMenuItem={renderEquipmentOptionItem}
									renderSelected={renderSelectedEquipment}
									valueKey="equipmentId"
									withCaret={true}
								/>
							}
							{
								disabled &&
								<>
									<div>Equipment</div>
									{
										!equipmentOption &&
										<div className={styles['field-value']}>N/A</div>
									}
									{
										equipmentOption &&
										<div className={styles['row-value']}>
											<ColorSquare color={equipmentOption.color} />
											<div className={styles['field-value']}>{equipmentOption.code} {equipmentOption.specification ? `(${equipmentOption.specification})` : ''}</div>
										</div>
									}
								</>
							}
						</Col>
						<Col md={12}>
							<div className={styles['datepicker-field']}>
								{
									!disabled &&
									<Field
										calendarSettings={calendarSettings}
										component={DateInput}
										id="dateNeeded"
										label="Date Needed *"
										name={OrderUpsertFM.getAttributeName('dateNeeded')}
										originalDateFormat={TimeFormat.DATE_ONLY}
										placeholderText={TimeFormat.DATE_ONLY}
									/>
								}
								{
									disabled &&
									<>
										<div>Date Needed</div>
										<div className={styles['field-value']}>{dateNeeded}</div>
									</>
								}
							</div>
							{
								!disabled &&
								<Field
									component={Dropdown}
									disabled={disabled}
									filterable={true}
									filterBy={['jobCode', 'title']}
									id="job_select"
									label="Job"
									name="jobId"
									onClear={onJobClear}
									onLazyLoad={lazyLoadJobs}
									options={jobOptions}
									placeholder="Select Job"
									propName={OrderUpsertFM.getAttributeName('job')}
									renderMenuItem={renderJobOptionItem}
									renderSelected={renderSelectedJob}
									valueKey="id"
									withCaret={true}
								/>
							}
							{
								disabled &&
								<>
									<div>Job</div>
									<div className={styles['field-value']}>{job?.jobCode ?? 'N/A'}</div>
								</>
							}
						</Col>
					</Row>
				</Form.Section>
				<Form.Section title="delivery details">
					<DeliveryMethodFields
						change={change}
						disabled={disabled}
						findAllLocationsForCompany={findAllLocationsForCompany}
						formDeliveryMethod={formDeliveryMethod}
						formName={form}
						formValues={formData}
					/>
				</Form.Section>
				<Form.Section title="notes">
					<Row className={styles['order-row']}>
						<Col md={24}>
							{
								!disabled &&
								<Field
									component={Textarea}
									disabled={disabled}
									label="Notes"
									maxCharacters={300}
									name={OrderUpsertFM.getAttributeName('notes')}
									placeholder="Enter Note"
									rows={1}
								/>
							}
							{
								disabled &&
								<>
									<div>Notes</div>
									<div className={styles['field-value']}>{notes}</div>
								</>
							}
						</Col>
					</Row>
				</Form.Section>
				<Form.Section title="delivery note">
					<Row className={styles['order-row']}>
						<Col md={24}>
							{
								!disabled &&
								<Field
									component={Textarea}
									disabled={disabled}
									label="Delivery Note"
									maxCharacters={300}
									name={OrderUpsertFM.getAttributeName('deliveryNote')}
									placeholder="Enter Message"
									rows={1}
								/>
							}
							{
								disabled &&
								<>
									<div>Delivery Note</div>
									<div className={styles['field-value']}>{deliveryNote}</div>
								</>
							}
						</Col>
					</Row>
				</Form.Section>
				<Form.Section title="order items">
					<Row className={styles['order-row']}>
						<Col md={24}>
							<FieldArray<OrderItemsProps>
								calculateAndSetTotal={calculateAndSetTotal}
								component={OrderItems}
								disabled={disabled}
								errors={errors.items}
								findItemDepartments={findItemDepartments}
								formValues={formData}
								initialized={isInEditMode ? initialized : true}
								isInEditMode={isInEditMode}
								name={OrderUpsertFM.getAttributeName('items')}
								rerenderOnEveryChange={true}
							/>
						</Col>
					</Row>
				</Form.Section>
			</Form>
			<ConfirmationModal
				body={leavePageModalBody}
				closeModal={closeLeavePageModal}
				footer={leavePageModalFooter()}
				modalStyle="danger"
				showModal={isLeavePageModalOpen}
				size="md"
				title="Leave Page?"
			/>
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	const formState = getFormValues(ORDER_FORM);
	const getErrors = getFormSyncErrors(ORDER_FORM);

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}
	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	const {
		deliveryMethod,
		status,
	} = formState(state) as OrderUpsertFM ?? ({} as Partial<OrderUpsertFM>);

	return {
		companyName: companyData.name,
		formDeliveryMethod: deliveryMethod,
		status,
		formData: getFormValues(ORDER_FORM)(state) as OrderUpsertFM,
		errors: getErrors(state) as ValidationErrors,
		canCreate: isAllowed(PagePermissions.COMPANY.ORDER.CREATE, permissions, isCompanyAdmin, role),
		canManage: isAllowed(PagePermissions.COMPANY.ORDER.MANAGE, permissions, isCompanyAdmin, role),
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

function mapDispatchToProps() {
	return {
		findForEdit: OrderActions.findForEdit,
		findAllEquipmentForCompany: EquipmentActions.findAllForCompanyList,
		findAllJobsForCompany: JobActions.getAllJobsForOrderForm,
		findAllLocationsForCompany: LocationActions.findList,
		findAllFieldWorkers: EmployeeAction.findAllEmployeesForWorkOrders,
		findItemDepartments: ItemActions.findItemDepartments,
		createOrder: OrderActions.createOrder,
		editOrder: OrderActions.editOrder,
	};
}

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	reduxForm({
		form: ORDER_FORM,
		validate: OrderUpsertFM.validate,
	}),
	React.memo
);

export default enhance(OrderUpsertForm);
