import * as React from 'react';

import { useReactTable, getCoreRowModel, createColumnHelper, AccessorFn, getSortedRowModel, SortingState, Cell, Row } from '@tanstack/react-table';
import { useDraggableColumns } from 'af-utils/react.util';

const CELL_MIN_WIDTH = 100;
const DISPLAY_CELL_WIDTH = 50;
const ACCESSOR_CELL_WIDTH = '1fr';

import styles from './styles.module.scss';
import BaseHeaderCell from './BaseHeaderCell';
import BaseCell from './BaseCell';
import { FooterButton, SimpleTableProps } from './types';

function SimpleTable<T>(props: React.PropsWithChildren<SimpleTableProps<T>>): React.ReactElement {
	const { rows, columns, label, footerButtons = [], disableAllSortings = false, rowClassName, bottomRows, footerComponent, emptyTableMessage } = props;
	const [tableGridLayout, setTableGridLayout] = React.useState<Record<string, string>>({});
	const { cellWidthsMap, columnRefs, onColumnDrag } = useDraggableColumns({
		minColumnWidth: CELL_MIN_WIDTH,
	});

	const [sorting, setSorting] = React.useState<SortingState>([]);
	const setHeaderReference = React.useCallback((headerId: string) => (_ref: HTMLSpanElement) => columnRefs.current[headerId] = _ref, [columnRefs]);

	const columnDefs = React.useMemo(() => {
		const columnHelper = createColumnHelper<T>();

		return columns.map((_column) => {
			if (_column.isDisplayColumn) {
				return columnHelper.display({
					id: _column.id,
					cell: _column.cell,
					header: _column.header ?? '',
					size: _column.size,
				});
			}

			return columnHelper.accessor(_column.accessor as unknown as AccessorFn<T>, {
				id: _column.id,
				cell: _column.cell,
				header: _column.header ?? '',
				size: _column.size,
				enableSorting: _column.enableSorting,
				sortingFn: _column.sortingFn ?? 'auto',
				sortDescFirst: _column.sortDescFirst,
			});
		});

	}, [columns]);

	const table = useReactTable({
		columns: columnDefs,
		data: rows ?? [],
		getSortedRowModel: getSortedRowModel(),
		getCoreRowModel: getCoreRowModel(),
		enableMultiSort: true,
		enableSortingRemoval: true,
		onSortingChange: setSorting,
		state: { sorting },
	});

	const bottomTable = useReactTable({
		columns: columnDefs,
		data: bottomRows ?? [],
		getCoreRowModel: getCoreRowModel(),
	});

	React.useEffect(() => {
		const newTableGridLayoutMap: Record<string, string> = {};

		for (const _column of table.getAllColumns()) {
			const isDisplayColumn = !_column.accessorFn;

			if (isDisplayColumn) {
				let cellWidth = '';
				if (!!_column.columnDef.size) {
					cellWidth = (typeof _column.columnDef.size === 'number') ? `${_column.columnDef.size}px` : _column.columnDef.size;
				} else {
					cellWidth = `${DISPLAY_CELL_WIDTH}px`;
				}
				newTableGridLayoutMap[_column.id] = _column.columnDef.meta?.isHidden ? '' : cellWidth;
			} else {
				let cellWidth = '';
				if (!!_column.columnDef.size) {
					cellWidth = (typeof _column.columnDef.size === 'number') ? `${_column.columnDef.size}px` : _column.columnDef.size;
				} else {
					cellWidth = `${ACCESSOR_CELL_WIDTH}`;
				}
				newTableGridLayoutMap[_column.id] = _column.columnDef.meta?.isHidden ? '' : cellWidth;
			}
		}

		setTableGridLayout(newTableGridLayoutMap);
	}, [table]);

	const gridTemplateColumns = React.useMemo(() => {
		const newTableGridLayoutMap = {};
		for (const _headerId of Object.keys(tableGridLayout)) {
			newTableGridLayoutMap[_headerId] = cellWidthsMap[_headerId]?.width
				? `${cellWidthsMap[_headerId]?.width}px`
				: tableGridLayout[_headerId];
		}

		return Object.values(newTableGridLayoutMap).join(' ');
	}, [cellWidthsMap, tableGridLayout]);

	const headersMapper = React.useCallback((_headerGroup) => {
		return (
			<div
				className={styles['simple-table__header-row']}
				key={_headerGroup.id}
				style={{ gridTemplateColumns }}
			>
				{_headerGroup.headers.map((_header) => {
					return (
						<BaseHeaderCell<T>
							areAllSortsDisabled={!!disableAllSortings}
							header={_header}
							key={_header.id}
							onColumnDrag={onColumnDrag}
							setHeaderReference={setHeaderReference}
						/>
					);
				})}
			</div>
		);
	}, [gridTemplateColumns, onColumnDrag, setHeaderReference, disableAllSortings]);

	const cellMapper = React.useCallback((_cell: Cell<T, unknown>) => {
		return (
			<BaseCell<T>
				cell={_cell}
				key={_cell.id}
			/>
		);
	}, []);

	const rowsMapper = React.useCallback((_row: Row<T>) => {
		const rowClassNameArray = [styles['simple-table__row']];

		if (rowClassName) {
			typeof rowClassName === 'string' ? rowClassNameArray.push(rowClassName) : rowClassNameArray.push(rowClassName(_row));
		}

		return (
			<div
				className={rowClassNameArray.join(' ')}
				key={_row.id}
				style={{ gridTemplateColumns }}
			>
				{_row.getVisibleCells().map(cellMapper)}
			</div>
		);
	}, [gridTemplateColumns, cellMapper, rowClassName]);

	const buttonsMapper = (_button: FooterButton) => {
		return (
			<span className={styles['simple-table__button']} key={_button.label} onClick={_button.onClick}>
				<span className={_button.iconName} />
				{_button.label}
			</span>
		);
	};

	return (
		<div className={styles['simple-table']}>
			{label && (
				<div className={styles['simple-table__label']}>
					{label}
				</div>
			)}
			<div className={styles['simple-table__body']}>
				{table.getHeaderGroups().map(headersMapper)}
				{table.getSortedRowModel().rows.length
					? table.getSortedRowModel().rows.map(rowsMapper)
					: !bottomTable.getCoreRowModel().rows.length && (
						<div className={styles['simple-table__empty-table-message']}>
							{emptyTableMessage ?? 'No Rows Found.'}
						</div>
					)}
				{bottomTable.getCoreRowModel().rows.map(rowsMapper)}
			</div>
			{footerComponent && (
				<div className={styles['simple-table__footer-component']}>
					{footerComponent()}
				</div>
			)}
			{!!footerButtons.length && (
				<div className={styles['simple-table__buttons']}>
					{footerButtons.map(buttonsMapper)}
				</div>
			)}
		</div>
	);
}

export default React.memo(SimpleTable) as typeof SimpleTable;
