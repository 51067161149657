import * as React from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import { CellContext } from '@tanstack/react-table';

import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import { DEFAULT_EQUIPMENT_IMAGE } from 'ab-constants/value';

import Input from 'af-fields/Input';
import SimpleTableField, { SimpleTableRow } from 'af-fields/SimpleTable';

import DropdownComponent from 'af-components/Controls/Dropdown';
import { SimpleTableProps } from 'af-components/Controls/SimpleTable/types';
import ImageTag from 'af-components/Image';

import ItemDepartmentVM from 'ab-viewModels/ItemDepartment/itemDepartment.viewModel';

import { dollarFormatter } from 'af-utils/format.util';

import FormModel from './formModel';
import styles from './styles.module.scss';
import { useToggle } from 'af-utils/react.util';

export interface OwnProps {
	availableItems: ItemDepartmentVM[];
	initialized: boolean;
	disabled: boolean;
}

type ItemFormModel = FormModel['items'][0];
type Props = OwnProps & WrappedFieldArrayProps<ItemFormModel>;

const renderItemOption = (item: ItemDepartmentVM) => (
	<>
		<ImageTag
			fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
			minSize={BlobStorageImageSizeContainer.SIZE_50X50}
			src={item.imageUrl}
			tryOriginal={true}
			tryRoot={true}
		/>
		<div className={styles['item-option']}>
			<span>{item.itemName} {item.partNumber ? `${item.partNumber}` : ''}</span>
			<span className={styles['item-option__details']}>
				{item.locationNickname} | {item.departmentName} | On stock: {item.currentStock}
			</span>
		</div>
	</>

);

const Order: React.FC<Props> = (props) => {
	const { fields, availableItems, initialized, disabled } = props;

	const {
		value: showSelectItemDropdown,
		setToFalse: hideItemDropdown,
		setToTrue: showItemDropdown,
	} = useToggle(false);

	const [itemOptions, setItemOptions] = React.useState<ItemDepartmentVM[]>([]);
	const [itemOptionsInitialized, setItemOptionsInitialized] = React.useState(false);

	React.useEffect(() => {
		const currentItems = fields.getAll();
		if (currentItems !== undefined && !itemOptionsInitialized && availableItems.length) {
			setItemOptions(availableItems.filter((i) => !currentItems?.find((c) => c.id === i.id)));
			setItemOptionsInitialized(true);
		}
	}, [availableItems, fields, itemOptionsInitialized]);

	const selectItem = React.useCallback((item: ItemDepartmentVM) => {
		fields.push({
			id: item.id,
			itemName: item.itemName,
			quantity: 1,
			price: item.price,
			isInEditMode: false,
		});
		setItemOptions(itemOptions.filter((i) => i.id !== item.id));
		hideItemDropdown();
	}, [fields, hideItemDropdown, itemOptions]);

	const renderSelectItemDropdown = React.useCallback(() => {
		return (
			<div className={styles['item-table-footer__dropdown']}>
				<div>
					<DropdownComponent<ItemDepartmentVM>
						containerClassName={styles['item-dropdown']}
						filterable={true}
						filterBy={['itemName', 'locationNickname', 'departmentName', 'partNumber']}
						fullWidth={true}
						id="item-dropdown"
						onValueChange={selectItem}
						options={itemOptions}
						placeholder="Select Item"
						renderMenuItem={renderItemOption}
						valueKey="id"
						withCaret={true}
					/>
				</div>
				<span className={`icon-close ${styles['item-table-footer__action']}`} onClick={hideItemDropdown} />
			</div >
		);
	}, [selectItem, itemOptions, hideItemDropdown]);

	const renderAddAction = React.useCallback(() => {
		if (disabled) {
			return;
		}
		return (
			<div className={styles['item-table-footer__action']} onClick={showItemDropdown}>
				<span className="icon-plus" />
				<span>Add Item</span>
			</div>
		);
	}, [showItemDropdown, disabled]);

	const columns: SimpleTableProps<ItemFormModel & SimpleTableRow>['columns'] = React.useMemo(() => [{
		id: 'quantity',
		cell: (_cell: CellContext<ItemFormModel & SimpleTableRow, number>) => {
			if (_cell.row.original.isInEditMode) {
				return (
					<Field
						component={Input}
						min={1}
						name={`${_cell.row.original.name}.quantity`}
						step={1}
						type="number"
					/>
				);
			}
			return _cell.getValue() ?? 'N/A';
		},
		header: 'Quantity',
		accessor: 'quantity',
		enableSorting: false,
	},
	{
		id: 'itemName',
		cell: (_cell: CellContext<ItemFormModel & SimpleTableRow, string>) => _cell.getValue() ?? 'N/A',
		header: 'Item Name',
		size: 300,
		accessor: 'itemName',
		enableSorting: true,
	},
	{
		id: 'price',
		cell: (_cell: CellContext<ItemFormModel & SimpleTableRow, number>) => {
			return dollarFormatter.format(_cell.getValue() ?? 0);
		},
		header: 'Price Each',
		accessor: 'price',
		enableSorting: false,
	},
	{
		id: 'extendedPrice',
		cell: (_cell: CellContext<ItemFormModel & SimpleTableRow, number>) => {
			return dollarFormatter.format(_cell.row.original.price * _cell.row.original.quantity);
		},
		header: 'Extended Price',
		accessor: 'extendedPrice',
	}], []);

	return (
		<SimpleTableField
			allowEdit={!disabled}
			columns={columns}
			fields={fields}
			footerComponent={showSelectItemDropdown ? renderSelectItemDropdown : renderAddAction}
			initialized={initialized}
			label="Items used for repair"
		/>
	);
};

export default React.memo(Order);
