import AccountPermission from 'acceligent-shared/enums/accountPermission';
import AccountPermissionTemplate from 'acceligent-shared/enums/accountPermissionTemplate';

const ADMIN_PERMISSIONS: AccountPermission[] = [];

const COMPANY_ADMIN_PERMISSIONS: AccountPermission[] = [
	AccountPermission.UA_MANAGE,
	AccountPermission.UA_READ,
	AccountPermission.LG_VIEW,
	AccountPermission.WR_MANAGE,
	AccountPermission.JB_MANAGE,
	AccountPermission.JB_MANAGE_DELIVERABLE,
	AccountPermission.JB_MANAGE_BILLING_CODES,
	AccountPermission.JB_MANAGE_PROJECTS,
	AccountPermission.JB_MANAGE_WORK_SUMMARY,
	AccountPermission.JB_MANAGE_INVOICES,
	AccountPermission.WO_MANAGE,
	AccountPermission.WO_SETTINGS,
	AccountPermission.PD_MANAGE,
	AccountPermission.SB_VIEW,
	AccountPermission.SB_SETTINGS,
	AccountPermission.MV_VIEW,
	AccountPermission.FR_MANAGEMENT_MANAGE,
	AccountPermission.FR_ACCOUNTING_MANAGE,
	AccountPermission.FR_ORDER,
	AccountPermission.FR_FILL,
	AccountPermission.FR_VIEW_ALL,
	AccountPermission.CR_ALL,
	AccountPermission.CR_EQUIPMENT_UTILIZATION,
	AccountPermission.CR_EMPLOYEE_UTILIZATION,
	AccountPermission.RS_EQUIPMENTCOST_MANAGE,
	AccountPermission.RS_EQUIPMENT_MANAGE,
	AccountPermission.TR_MANAGE,
	AccountPermission.RS_EQUIPMENT_STATUS_MANAGE,
	AccountPermission.RS_WAGERATE_MANAGE,
	AccountPermission.RS_EMPLOYEE_MANAGE,
	AccountPermission.RS_EMPLOYEE_STATUS_MANAGE,
	AccountPermission.RS_FIELD_WORK_CLASSIFICATION_CODE_MANAGE,
	AccountPermission.RS_CREWTYPE_MANAGE,
	AccountPermission.RS_SKILL_MANAGE,
	AccountPermission.RS_SALE_MANAGE,
	AccountPermission.RS_LOCATION_MANAGE,
	AccountPermission.RS_DIVISION_MANAGE,
	AccountPermission.RS_DOCUMENT_MANAGE,
	AccountPermission.RS_DELIVERABLE_MANAGE,
	AccountPermission.CT_MANAGE,
	AccountPermission.CT_VIEW,
	AccountPermission.UG_MANAGE,
	AccountPermission.NTFY_MANAGE,
	AccountPermission.COMM_MANAGE,
	AccountPermission.COMM_HISTORY_VIEW,
	AccountPermission.PROD_DATA_VIEW,
	AccountPermission.PROD_DATA_EDIT,
	AccountPermission.EQ_MANAGE,
	AccountPermission.EM_MANAGE,
	AccountPermission.TEM_MANAGE,
	AccountPermission.WS_MANAGE,
	AccountPermission.AC_ALL,
	AccountPermission.AC_JOB_PAYROLL,
	AccountPermission.AC_PER_DIEM,
	AccountPermission.AC_PREVAILING,
	AccountPermission.AC_WORK_SUMMARY,
	AccountPermission.AC_MANAGE_INVOICES,
];

const GENERAL_MANAGER_PERMISSIONS: AccountPermission[] = [
	AccountPermission.UA_READ,
	AccountPermission.WR_MANAGE,
	AccountPermission.JB_MANAGE,
	AccountPermission.WO_MANAGE,
	AccountPermission.WO_SETTINGS,
	AccountPermission.SB_VIEW,
	AccountPermission.SB_SETTINGS,
	AccountPermission.CR_ALL,
	AccountPermission.RS_EQUIPMENTCOST_MANAGE,
	AccountPermission.RS_EQUIPMENT_MANAGE,
	AccountPermission.TR_MANAGE,
	AccountPermission.RS_WAGERATE_MANAGE,
	AccountPermission.RS_EMPLOYEE_MANAGE,
	AccountPermission.RS_CREWTYPE_MANAGE,
	AccountPermission.RS_SKILL_MANAGE,
	AccountPermission.RS_SALE_MANAGE,
	AccountPermission.RS_LOCATION_MANAGE,
	AccountPermission.RS_DIVISION_MANAGE,
	AccountPermission.RS_DOCUMENT_MANAGE,
	AccountPermission.RS_EMPLOYEE_STATUS_MANAGE,
	AccountPermission.RS_EQUIPMENT_STATUS_MANAGE,
	AccountPermission.PD_MANAGE,
	AccountPermission.CT_VIEW,
	AccountPermission.TEM_MANAGE,
	AccountPermission.WS_MANAGE,
];

const FINANCE_PERMISSIONS: AccountPermission[] = [
	AccountPermission.WR_MANAGE,
	AccountPermission.JB_MANAGE,
	AccountPermission.JB_MANAGE_BILLING_CODES,
	AccountPermission.JB_MANAGE_DELIVERABLE,
	AccountPermission.JB_MANAGE_WORK_SUMMARY,
	AccountPermission.JB_MANAGE_INVOICES,
	AccountPermission.WO_MANAGE,
	AccountPermission.FR_FILL,
	AccountPermission.FR_ACCOUNTING_MANAGE,
	AccountPermission.SB_VIEW,
	AccountPermission.CR_ALL,
	AccountPermission.RS_EQUIPMENTCOST_MANAGE,
	AccountPermission.RS_WAGERATE_MANAGE,
	AccountPermission.RS_EMPLOYEE_MANAGE,
	AccountPermission.RS_CREWTYPE_MANAGE,
	AccountPermission.RS_SKILL_MANAGE,
	AccountPermission.RS_SALE_MANAGE,
	AccountPermission.RS_LOCATION_MANAGE,
	AccountPermission.RS_DIVISION_MANAGE,
	AccountPermission.RS_DOCUMENT_MANAGE,
	AccountPermission.RS_EMPLOYEE_STATUS_MANAGE,
	AccountPermission.PD_MANAGE,
	AccountPermission.CT_VIEW,
	AccountPermission.CT_MANAGE,
	AccountPermission.FR_ORDER,
	AccountPermission.TEM_MANAGE,
	AccountPermission.WS_MANAGE,
	AccountPermission.AC_MANAGE_INVOICES,
	AccountPermission.OR_MANAGE,
	AccountPermission.PO_MANAGE,
];

const SALES_PERMISSIONS: AccountPermission[] = [
	AccountPermission.WR_MANAGE,
	AccountPermission.SB_VIEW,
	AccountPermission.PD_MANAGE,
	AccountPermission.CT_VIEW,
];

const CHIEF_ESTIMATOR_PERMISSIONS: AccountPermission[] = [
	AccountPermission.WR_MANAGE,
	AccountPermission.SB_VIEW,
	AccountPermission.CR_ALL,
	AccountPermission.RS_EQUIPMENTCOST_MANAGE,
	AccountPermission.RS_EQUIPMENT_MANAGE,
	AccountPermission.TR_MANAGE,
	AccountPermission.RS_WAGERATE_MANAGE,
	AccountPermission.RS_EMPLOYEE_MANAGE,
	AccountPermission.RS_CREWTYPE_MANAGE,
	AccountPermission.RS_SKILL_MANAGE,
	AccountPermission.RS_SALE_MANAGE,
	AccountPermission.RS_DOCUMENT_MANAGE,
	AccountPermission.RS_EMPLOYEE_STATUS_MANAGE,
	AccountPermission.RS_EQUIPMENT_STATUS_MANAGE,
	AccountPermission.PD_MANAGE,
	AccountPermission.CT_VIEW,
];

const ESTIMATOR_PERMISSIONS: AccountPermission[] = [
	AccountPermission.WR_MANAGE,
	AccountPermission.SB_VIEW,
	AccountPermission.CR_ALL,
	AccountPermission.RS_EQUIPMENTCOST_MANAGE,
	AccountPermission.RS_EQUIPMENT_MANAGE,
	AccountPermission.TR_MANAGE,
	AccountPermission.RS_WAGERATE_MANAGE,
	AccountPermission.RS_EMPLOYEE_MANAGE,
	AccountPermission.RS_SALE_MANAGE,
	AccountPermission.RS_DOCUMENT_MANAGE,
	AccountPermission.RS_EMPLOYEE_STATUS_MANAGE,
	AccountPermission.RS_EQUIPMENT_STATUS_MANAGE,
	AccountPermission.PD_MANAGE,
	AccountPermission.CT_VIEW,
];

const PROJECT_MANAGER_PERMISSIONS: AccountPermission[] = [
	AccountPermission.WR_MANAGE,
	AccountPermission.JB_MANAGE,
	AccountPermission.WO_MANAGE,
	AccountPermission.WO_SETTINGS,
	AccountPermission.SB_VIEW,
	AccountPermission.SB_SETTINGS,
	AccountPermission.CR_ALL,
	AccountPermission.RS_EQUIPMENTCOST_MANAGE,
	AccountPermission.RS_EQUIPMENT_MANAGE,
	AccountPermission.TR_MANAGE,
	AccountPermission.RS_WAGERATE_MANAGE,
	AccountPermission.RS_EMPLOYEE_MANAGE,
	AccountPermission.RS_CREWTYPE_MANAGE,
	AccountPermission.RS_SKILL_MANAGE,
	AccountPermission.RS_SALE_MANAGE,
	AccountPermission.RS_DOCUMENT_MANAGE,
	AccountPermission.RS_EMPLOYEE_STATUS_MANAGE,
	AccountPermission.RS_EQUIPMENT_STATUS_MANAGE,
	AccountPermission.PD_MANAGE,
	AccountPermission.FR_FILL,
	AccountPermission.CT_VIEW,
	AccountPermission.TEM_MANAGE,
	AccountPermission.WS_MANAGE,
];

const DISPATCHER_PERMISSIONS: AccountPermission[] = [
	AccountPermission.WR_MANAGE,
	AccountPermission.JB_MANAGE,
	AccountPermission.WO_MANAGE,
	AccountPermission.WO_SETTINGS,
	AccountPermission.SB_VIEW,
	AccountPermission.SB_SETTINGS,
	AccountPermission.RS_EQUIPMENT_MANAGE,
	AccountPermission.TR_MANAGE,
	AccountPermission.RS_EMPLOYEE_MANAGE,
	AccountPermission.RS_CREWTYPE_MANAGE,
	AccountPermission.RS_SKILL_MANAGE,
	AccountPermission.RS_EMPLOYEE_STATUS_MANAGE,
	AccountPermission.RS_EQUIPMENT_STATUS_MANAGE,
	AccountPermission.NTFY_MANAGE,
	AccountPermission.CT_VIEW,
	AccountPermission.TEM_MANAGE,
];

const SUPERINTENDENT_PERMISSIONS: AccountPermission[] = [
	AccountPermission.JB_MANAGE,
	AccountPermission.WO_MANAGE,
	AccountPermission.WO_SETTINGS,
	AccountPermission.SB_VIEW,
	AccountPermission.SB_SETTINGS,
	AccountPermission.RS_EQUIPMENT_MANAGE,
	AccountPermission.TR_MANAGE,
	AccountPermission.RS_EMPLOYEE_MANAGE,
	AccountPermission.RS_SKILL_MANAGE,
	AccountPermission.PD_MANAGE,
	AccountPermission.FR_FILL,
	AccountPermission.CT_VIEW,
	AccountPermission.TEM_MANAGE,
	AccountPermission.WS_MANAGE,
	AccountPermission.OR_CREATE,
	AccountPermission.PO_CREATE,
];

const MECHANIC_PERMISSIONS: AccountPermission[] = [
	AccountPermission.WO_MANAGE,
	AccountPermission.SB_VIEW,
	AccountPermission.RS_EQUIPMENT_MANAGE,
	AccountPermission.TR_MANAGE,
	AccountPermission.RS_EQUIPMENT_STATUS_MANAGE,
	AccountPermission.CT_VIEW,
	AccountPermission.MV_VIEW,
	AccountPermission.EQ_MANAGE,
	AccountPermission.TEM_MANAGE,
];

const DELIVERABLE_PERMISSIONS = [
	AccountPermission.RS_DELIVERABLE_MANAGE,
	AccountPermission.CT_VIEW,
	AccountPermission.SB_VIEW,
	AccountPermission.FR_VIEW_ALL,
];

const FIELD_WORKER_PERMISSIONS: AccountPermission[] = [
	AccountPermission.FR_FILL,
	AccountPermission.OR_CREATE,
];

const OFFICE_WORKER_PERMISSIONS: AccountPermission[] = [];

const AUDITOR_PERMISSIONS: AccountPermission[] = [
	AccountPermission.LG_VIEW,
	AccountPermission.PROD_DATA_VIEW,
	AccountPermission.FR_VIEW_ALL,
	AccountPermission.FR_FILL,
	AccountPermission.JB_MANAGE_DELIVERABLE,
	AccountPermission.JB_MANAGE_WORK_SUMMARY,
	AccountPermission.JB_MANAGE_INVOICES,
	AccountPermission.JB_MANAGE_WORK_SUMMARY,
	AccountPermission.JB_MANAGE_BILLING_CODES,
	AccountPermission.JB_MANAGE,
	AccountPermission.CR_ALL,
	AccountPermission.CR_EMPLOYEE_UTILIZATION,
	AccountPermission.CR_EQUIPMENT_UTILIZATION,
	AccountPermission.PD_MANAGE,
	AccountPermission.AC_ALL,
	AccountPermission.WS_MANAGE,
];

export const AccountPermissionTemplateLabel = {
	[AccountPermissionTemplate.ADMIN]: 'Admin',
	[AccountPermissionTemplate.COMPANY_ADMIN]: 'Company Admin',
	[AccountPermissionTemplate.GENERAL_MANAGER]: 'General Manager',
	[AccountPermissionTemplate.FINANCE]: 'Finance',
	[AccountPermissionTemplate.SALES]: 'Sales',
	[AccountPermissionTemplate.CHIEF_ESTIMATOR]: 'Chief Estimator',
	[AccountPermissionTemplate.ESTIMATOR]: 'Estimator',
	[AccountPermissionTemplate.PROJECT_MANAGER]: 'Project Manager',
	[AccountPermissionTemplate.DISPATCHER]: 'Dispatcher',
	[AccountPermissionTemplate.SUPERINTENDENT]: 'Superintendent',
	[AccountPermissionTemplate.DELIVERABLE]: 'Deliverable',
	[AccountPermissionTemplate.MECHANIC]: 'Mechanic',
	[AccountPermissionTemplate.FIELD_WORKER]: 'Field Worker',
	[AccountPermissionTemplate.OFFICE_WORKER]: 'Office Worker',
	[AccountPermissionTemplate.AUDITOR]: 'Auditor',
	/** Added for legacy account definition. Shouldn't be assigned to new users */
	[AccountPermissionTemplate.CUSTOM]: 'Custom',
	/** Replaces accountType.USER for deleted accounts. Can be set only by soft deleting user from the company */
	[AccountPermissionTemplate.DELETED]: 'Deleted',
};

export const AccountPermissionTemplates: Record<AccountPermissionTemplate, AccountPermission[]> = {
	[AccountPermissionTemplate.ADMIN]: ADMIN_PERMISSIONS,
	[AccountPermissionTemplate.COMPANY_ADMIN]: COMPANY_ADMIN_PERMISSIONS,
	[AccountPermissionTemplate.GENERAL_MANAGER]: GENERAL_MANAGER_PERMISSIONS,
	[AccountPermissionTemplate.FINANCE]: FINANCE_PERMISSIONS,
	[AccountPermissionTemplate.SALES]: SALES_PERMISSIONS,
	[AccountPermissionTemplate.CHIEF_ESTIMATOR]: CHIEF_ESTIMATOR_PERMISSIONS,
	[AccountPermissionTemplate.ESTIMATOR]: ESTIMATOR_PERMISSIONS,
	[AccountPermissionTemplate.PROJECT_MANAGER]: PROJECT_MANAGER_PERMISSIONS,
	[AccountPermissionTemplate.DISPATCHER]: DISPATCHER_PERMISSIONS,
	[AccountPermissionTemplate.SUPERINTENDENT]: SUPERINTENDENT_PERMISSIONS,
	[AccountPermissionTemplate.DELIVERABLE]: DELIVERABLE_PERMISSIONS,
	[AccountPermissionTemplate.MECHANIC]: MECHANIC_PERMISSIONS,
	[AccountPermissionTemplate.FIELD_WORKER]: FIELD_WORKER_PERMISSIONS,
	[AccountPermissionTemplate.OFFICE_WORKER]: OFFICE_WORKER_PERMISSIONS,
	[AccountPermissionTemplate.AUDITOR]: AUDITOR_PERMISSIONS,
	[AccountPermissionTemplate.CUSTOM]: [],
	[AccountPermissionTemplate.DELETED]: [],
};

export const AccountPermissionFlags: Record<AccountPermissionTemplate, string[]> = {
	[AccountPermissionTemplate.ADMIN]: [],
	[AccountPermissionTemplate.COMPANY_ADMIN]: [],
	[AccountPermissionTemplate.GENERAL_MANAGER]: [],
	[AccountPermissionTemplate.FINANCE]: ['assignableAsAccounting'],
	[AccountPermissionTemplate.SALES]: [],
	[AccountPermissionTemplate.CHIEF_ESTIMATOR]: [],
	[AccountPermissionTemplate.ESTIMATOR]: [],
	[AccountPermissionTemplate.PROJECT_MANAGER]: ['assignableToWorkOrder', 'assignableAsProjectManager'],
	[AccountPermissionTemplate.DISPATCHER]: [],
	[AccountPermissionTemplate.SUPERINTENDENT]: ['assignableToWorkOrder', 'assignableAsSuperintendent'],
	[AccountPermissionTemplate.DELIVERABLE]: ['assignableAsQAQC'],
	[AccountPermissionTemplate.MECHANIC]: [],
	[AccountPermissionTemplate.FIELD_WORKER]: ['assignableToWorkOrder'],
	[AccountPermissionTemplate.OFFICE_WORKER]: [],
	[AccountPermissionTemplate.AUDITOR]: [],
	[AccountPermissionTemplate.CUSTOM]: [],
	[AccountPermissionTemplate.DELETED]: [],
};

export const AccountPermissionTemplateOptions = [
	{ id: AccountPermissionTemplate.ADMIN, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.ADMIN] },
	{ id: AccountPermissionTemplate.COMPANY_ADMIN, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.COMPANY_ADMIN] },
	{ id: AccountPermissionTemplate.GENERAL_MANAGER, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.GENERAL_MANAGER] },
	{ id: AccountPermissionTemplate.FINANCE, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.FINANCE] },
	{ id: AccountPermissionTemplate.SALES, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.SALES] },
	{ id: AccountPermissionTemplate.CHIEF_ESTIMATOR, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.CHIEF_ESTIMATOR] },
	{ id: AccountPermissionTemplate.ESTIMATOR, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.ESTIMATOR] },
	{ id: AccountPermissionTemplate.PROJECT_MANAGER, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.PROJECT_MANAGER] },
	{ id: AccountPermissionTemplate.DISPATCHER, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.DISPATCHER] },
	{ id: AccountPermissionTemplate.SUPERINTENDENT, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.SUPERINTENDENT] },
	{ id: AccountPermissionTemplate.DELIVERABLE, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.DELIVERABLE] },
	{ id: AccountPermissionTemplate.MECHANIC, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.MECHANIC] },
	{ id: AccountPermissionTemplate.FIELD_WORKER, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.FIELD_WORKER] },
	{ id: AccountPermissionTemplate.OFFICE_WORKER, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.OFFICE_WORKER] },
	{ id: AccountPermissionTemplate.AUDITOR, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.AUDITOR] },
	// added and commented so no-one adds options by accident
	// { id: AccountPermissionTemplate.CUSTOM, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.CUSTOM] },
	// { id: AccountPermissionTemplate.DELETED, label: AccountPermissionTemplateLabel[AccountPermissionTemplate.DELETED] },
];
