import { Dispatch, AnyAction } from 'redux';

import { saveAs } from 'file-saver';

import BlobStorageContainer from 'acceligent-shared/enums/blobStorageContainer';
import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import { AttachmentsForm, ResizeImageViewModel } from 'ab-requestModels/attachment.requestModel';
import DeletePendingAttachmentsRM from 'ab-requestModels/attachment/deletePendingAttachments.requestModel';

import { PendingAttachmentVM } from 'ab-viewModels/attachment/pendingAttachment.viewModel';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { GetRootState } from 'af-reducers';
import * as httpActions from 'af-reducers/http.reducer';

export function downloadAttachment(attachmentId: number, fileName: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = await http.get(API.V1.ATTACHMENT.DOWNLOAD(attachmentId), { responseType: 'blob' });
			saveAs(data, fileName);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function downloadAttachmentsAsZip(attachmentIds: number[], filename: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = await http.get(API.V1.ATTACHMENT.DOWNLOAD_AS_ZIP(attachmentIds), { responseType: 'blob' });
			saveAs(data, filename);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function downloadAttachmentForPreview(attachmentId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<{ data: Buffer; }>(API.V1.ATTACHMENT.DOWNLOAD_FOR_VIEWER(attachmentId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getPresignedGetUrl(directory: string, filename: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<string>(API.V1.ATTACHMENT.SIGNED_URL(undefined, directory, filename));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function resizeImage(directory: string, filename: string, size: BlobStorageImageSizeContainer, addIfMissing: boolean = false) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post(API.V1.ATTACHMENT.RESIZE_IMAGE(), { directory, filename, size, addIfMissing } as ResizeImageViewModel);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

interface AttachmentFile {
	requestFiles?: File[];
}

export function uploadAttachmentsToDirectory(directoryId: number, file: AttachmentFile) {
	return async (dispatch: Dispatch<httpActions.UploadProgress | httpActions.UploadProgressSize>, { redirectTo }) => {
		const action = async () => {
			const options = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress: (progressEvent) => {
					const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
					if (totalLength !== null) {
						dispatch(httpActions.UPLOAD_PROGRESS(Math.round((progressEvent.loaded * 100) / totalLength)));
						dispatch(httpActions.UPLOAD_PROGRESS_SIZE(progressEvent.loaded));
					}
				},
			};
			const fd = new FormData();
			file.requestFiles?.forEach((_file) => fd.append('requestFiles[]', _file));

			await http.post(API.V1.ATTACHMENT.UPLOAD_BULK(directoryId), fd, options);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};

}

export interface CopyAttachmentToWO {
	directoryId: number;
	copyToWO: boolean;
}
export function copyAttachmentToWO(attachmentId: number, directoryId: number, workRequestId: number, copyToWO: boolean) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const route = API.V1.ATTACHMENT.COPY_TO_WO(attachmentId);
			await http.put(route, { workRequestId, copyToWO, directoryId });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteAttachment(attachmentId: number, directoryId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.delete(API.V1.ATTACHMENT.DELETE(attachmentId), { directoryId });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function renameAttachment(attachmentId: number, name: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post(API.V1.ATTACHMENT.RENAME(attachmentId), { name });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function uploadPendingAttachments(form: AttachmentsForm, storageContainer: BlobStorageContainer) {
	return async (dispatch: Dispatch<httpActions.UploadProgress | httpActions.UploadProgressSize>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const options = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = new FormData();
			form.requestFiles?.forEach((_file) => fd.append('requestFiles[]', _file));
			return await http.post<PendingAttachmentVM[]>((API.V1.ATTACHMENT.ADD_PENDING(storageContainer)), fd, options);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deletePendingAttachments(event: DeletePendingAttachmentsRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.delete<void>(API.V1.ATTACHMENT.DELETE_PENDING, event);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function changeAttachmentParent(attachmentId: number, sourceDirId: number, destinationDirId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post(API.V1.ATTACHMENT.CHANGE_PARENT(attachmentId), { sourceDirId, destinationDirId });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
