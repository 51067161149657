import { Dispatch, AnyAction } from 'redux';
import { SubmissionError } from 'redux-form';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import { TableVM } from 'ab-viewModels/account/table.viewModel';
import { ActiveVM } from 'ab-viewModels/account/active.viewModel';
import { CsvVM } from 'ab-viewModels/account/csv.viewModel';
import { PreviewVM } from 'ab-viewModels/account/preview.viewModel';
import { AccountViewModel } from 'ab-viewModels/account.viewModel';
import MemberVM from 'ab-viewModels/account/member.viewModel';
import { CSVBulkAccountVM } from 'ab-viewModels/account/upload.viewModel';
import * as User from 'ab-viewModels/user.viewModel';
import { ActiveUserVM } from 'ab-viewModels/user/activeUser.viewModel';
import AccountOptionVM from 'ab-viewModels/account/option.viewModel';
import DigitalSignatureVM from 'ab-viewModels/user/digitalSignature.viewModel';
import AccountAssignableToWorkOrderVM from 'ab-viewModels/account/assignableToWorkOrder.viewModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { AccountRM } from 'ab-requestModels/account/updateActive.requestModel';
import InviteAccountRM from 'ab-requestModels/account/invite.requestModel';
import CreateAccountRM from 'ab-requestModels/account/upsert.requestModel';
import { CSVBulkAccountRM, BulkAccountRM } from 'ab-requestModels/account/upload.requestModel';
import UpdateDigitalSignatureRM from 'ab-requestModels/account/updateDigitalSignature.requestModel';

import API from 'af-constants/routes/api';
import { ACCOUNT_EDIT, MEMBER_INVITE } from 'af-constants/reduxForms';

import * as FormUtil from 'ab-utils/form.util';

import { http } from 'af-utils/http.util';
import { errorHandler, ErrorOverride } from 'af-utils/actions.util';

import * as authenticationActionCreators from 'af-actions/authentication/authentication.actionCreators';
import * as companyActionCreators from 'af-actions/companies/companies.actionCreators';

import { GetRootState } from 'af-reducers';

// #region CREATE

export function create(form: CreateAccountRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const options = {
				submitting: MEMBER_INVITE,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, 'imageUrl');

			await http.post(API.V1.ACCOUNT.CREATE, fd, options);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function addExisting(form: InviteAccountRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.ACCOUNT.INVITE_EXISTING_MEMBER, form, { submitting: MEMBER_INVITE });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #endregion
// #region READ

export function findById(accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<MemberVM>(API.V1.ACCOUNT.BY_ID(accountId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findActiveAccount() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<ActiveVM>(API.V1.ACCOUNT.ACTIVE);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<TableVM>>(API.V1.ACCOUNT.COMPANY_TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllDeletedForCompanyTable(tableRequestModel: TableQuery = {} as TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<TableVM>>(API.V1.ACCOUNT.COMPANY_TABLE_DELETED(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyList(isDeleted?: boolean) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<CsvVM[]>(API.V1.ACCOUNT.CSV_EXPORT(isDeleted));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyPreview() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<PreviewVM[]>(API.V1.ACCOUNT.ACCOUNTS_FOR_COMPANY);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllAssignableForDeliverable() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<AccountViewModel[]>(API.V1.ACCOUNT.FIND_FOR_DELIVERABLE);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllAssignableAsAccounting() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<AccountOptionVM[]>(API.V1.ACCOUNT.FIND_ACCOUNTING_OPTIONS);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllAssignableAsTechnician() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<AccountOptionVM[]>(API.V1.ACCOUNT.FIND_TECHNICIAN_OPTIONS);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllAssignableAsManagement() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<AccountOptionVM[]>(API.V1.ACCOUNT.FIND_MANAGEMENT_OPTIONS);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllAssignableToWorkOrder() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<AccountAssignableToWorkOrderVM[]>(API.V1.ACCOUNT.FIND_ASSIGNABLE_TO_WORK_ORDER);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllAssignableAsSuperintendent() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<AccountOptionVM[]>(API.V1.ACCOUNT.FIND_ASSIGNABLE_TO_WORK_ORDER);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #endregion
// #region UPDATE

export function activate(accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put(API.V1.ACCOUNT.ACTIVATE(accountId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function resendEmailInvite(accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.ACCOUNT.RESEND_EMAIL_INVITE(accountId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function resendPhoneInvite(accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.ACCOUNT.RESEND_PHONE_INVITE(accountId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function update(form: CreateAccountRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			if (!form.id) {
				throw new Error('Account ID not provided');
			}

			const options = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, 'imageUrl');
			await http.put(API.V1.ACCOUNT.BY_ID(form.id), fd, options);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateActiveAccount(form: AccountRM) {
	return async (
		dispatch: Dispatch<authenticationActionCreators.AuthenticationAction>,
		getState: GetRootState, { redirectTo }
	) => {
		const action = async () => {

			const options = {
				submitting: ACCOUNT_EDIT,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, 'image');
			const user = await http.put<ActiveUserVM>(API.V1.ACCOUNT.ACTIVE, fd, options);
			const userData = {
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				phoneNumber: user.phoneNumber,
				countryCode: user.countryCode,
				isDigitalSignatureEnabled: user.isDigitalSignatureEnabled,
				digitalSignatureUrl: user.digitalSignatureUrl,
				digitalSignatureId: user.digitalSignatureId,
			} as User.UserData;
			dispatch(authenticationActionCreators.UPDATE_USER(userData));

			return user;
		};
		const error: ErrorOverride = {
			err404: () => {
				throw new SubmissionError({ _error: 'Old password incorrect' });
			},
		};
		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function saveDigitalSignature(form: UpdateDigitalSignatureRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const signature = await http.patch<DigitalSignatureVM>(API.V1.ACCOUNT.SAVE_DIGITAL_SIGNATURE, form);
			const userData = {
				isDigitalSignatureEnabled: signature.isDigitalSignatureEnabled,
				digitalSignatureUrl: signature.digitalSignatureUrl,
				digitalSignatureId: signature.digitalSignatureId,
				showCreateDigitalSignature: signature.showCreateDigitalSignature,
			} as User.UserData;
			dispatch(authenticationActionCreators.UPDATE_USER(userData));
			return signature;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #endregion
// #region DELETE

export function remove(accountId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.ACCOUNT.BY_ID(accountId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #endregion

// #region CSV IMPORT

export function validateCSV(members: CSVBulkAccountRM['accounts']) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {

			const response = await http.post<CSVBulkAccountVM>(API.V1.ACCOUNT.CSV_VALIDATE, members);

			dispatch(companyActionCreators.SET_ACCEPTED_MEMBERS(response.valid));
			dispatch(companyActionCreators.SET_REJECTED_MEMBERS(response.invalid));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function upload(form: BulkAccountRM) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.post(API.V1.ACCOUNT.CSV_UPLOAD, form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

// #endregion
