import * as React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { Col, Row } from 'react-bootstrap';

import FieldWorkClassificationCodeListItemVM from 'acceligent-shared/dtos/web/view/fieldWorkClassificationCode/listItem';
import { EquipmentListItemVM } from 'acceligent-shared/dtos/web/view/timeSplitEquipment/timeSplitEquipment';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { bemElement } from 'ab-utils/bem.util';

import { SubjobVM } from 'ab-viewModels/workRequest/jobUpsert.viewModel';

import LockedValue from 'af-components/LockedValue';
import Tooltip from 'af-components/Tooltip';

import Dropdown from 'af-fields/Dropdown';

import EquipmentItem from './EquipmentItem';
import { TimeSplitEntryAction } from '../TimeSheetEditModalBody';
import { TimeSplitEntryFormModel } from '../formModel';
import TimeIntervalDropdown from '../../../../Shared/TimeIntervalDropdown';

type TimeSplitEntryEquipmentSections = {
	title: string;
	options: EquipmentListItemVM[];
};

interface OwnProps {
	index: number;
	canEditClassificationCodes: boolean;
	canSeeTimeAllocations: boolean;
	classificationCodes: FieldWorkClassificationCodeListItemVM[];
	readonly: boolean;
	sections: Nullable<TimeSplitEntryEquipmentSections>[];
	classificationCodeIdsWithValues: Record<number, string>;
	equipmentIdsWithValues: Record<number, string>;
	subJobs: SubjobVM[];
	entry: TimeSplitEntryFormModel;
	change: (fieldName: string, value) => void;
	getEntry: (index: number) => TimeSplitEntryFormModel;
	removeEntry: (index: number) => void;
	changeEntries: (action: TimeSplitEntryAction, entryIndex: Nullable<number>) => void;
}

const FILTER_CLASSIFICATION_CODE_BY = ['code'];
const FILTER_EQUIPMENT_BY: (keyof EquipmentListItemVM)[] = ['code', 'specification'];

type Props = OwnProps & WrappedFieldProps;

const TimeSplitEntry: React.FC<Props> = (props: Props) => {

	const {
		index,
		canEditClassificationCodes,
		classificationCodes,
		sections,
		removeEntry,
		readonly,
		input,
		entry,
		change,
		changeEntries,
		classificationCodeIdsWithValues,
		equipmentIdsWithValues,
		subJobs,
		canSeeTimeAllocations,
	} = props;

	const classificationCodeName = React.useMemo(() => `${input.name}.fieldWorkClassificationCodeId`, [input.name]);
	const equipmentName = React.useMemo(() => `${input.name}.equipmentId`, [input.name]);
	const durationName = React.useMemo(() => `${input.name}.duration`, [input.name]);
	const allocatedWorkRequestName = React.useMemo(() => `${input.name}.allocatedWorkRequestId`, [input.name]);

	const timeValue = React.useMemo(() => TimeUtils.minutesToHoursAndMinutes(entry.duration), [entry.duration]);

	const onFieldChange = React.useCallback((fieldName: string) => {
		return (value) => {
			if (fieldName === equipmentName) {
				value = value.id;
			}
			change(fieldName, value);
			changeEntries(TimeSplitEntryAction.FIELD_CHANGED, index);
		};
	}, [change, changeEntries, equipmentName, index]);

	const onDeleteEntry = React.useCallback(() => {
		removeEntry(index);
	}, [removeEntry, index]);

	const selectedClassificationCodeValue = React.useMemo(() => entry.fieldWorkClassificationCodeId ?
		classificationCodeIdsWithValues[entry.fieldWorkClassificationCodeId]
		: undefined, [classificationCodeIdsWithValues, entry.fieldWorkClassificationCodeId]);
	const selectedEquipmentValue = React.useMemo(() => entry.equipmentId ?
		equipmentIdsWithValues[entry.equipmentId]
		: undefined, [entry.equipmentId, equipmentIdsWithValues]);
	const subjob = React.useMemo(() => subJobs.find((_e) => _e.id === entry.allocatedWorkRequestId)?.title, [entry.allocatedWorkRequestId, subJobs]);
	if (readonly) {

		return (
			<div className={bemElement('time-sheet-list__equipment-modal__time-split-list', 'row')} key={`time-split-${entry.virtualId}`}>
				<Row>
					{
						canEditClassificationCodes &&
						<Col md={6}><LockedValue label="Classification Code" value={selectedClassificationCodeValue} /></Col>
					}
					<Col md={canEditClassificationCodes ? (canSeeTimeAllocations ? 6 : 8) : 10}><LockedValue label="Equipment" value={selectedEquipmentValue} /></Col>

					<Col md={canEditClassificationCodes ? (canSeeTimeAllocations ? 6 : 8) : 6}><LockedValue label="Time" value={timeValue} /></Col>

					{
						canSeeTimeAllocations &&
						<Col md={6}><LockedValue label="Allocate to sub-job" value={subjob} /></Col>
					}
				</Row>
			</div>
		);
	}

	return (
		<div className={bemElement('time-sheet-list__equipment-modal__time-split-list', 'row')} key={`time-split-${entry.virtualId}`}>
			<Row>
				{canEditClassificationCodes &&
					<Col className={bemElement('time-sheet-list__equipment-modal__time-split-list__row', 'classification-code')} sm={5}>
						<Field
							component={Dropdown}
							filterable
							filterBy={FILTER_CLASSIFICATION_CODE_BY}
							hasBlankOption
							id={classificationCodeName}
							label="Classification Code"
							labelKey="code"
							name={classificationCodeName}
							onChange={onFieldChange(classificationCodeName)}
							options={classificationCodes}
							value={entry.fieldWorkClassificationCodeId}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				}
				<Col sm={canEditClassificationCodes && canSeeTimeAllocations ? 7 : 9}>
					<Field
						component={Dropdown}
						containerClassName={bemElement('time-sheet-list__equipment-modal', 'equipment')}
						filterable={true}
						filterBy={FILTER_EQUIPMENT_BY}
						id={equipmentName}
						label="Equipment"
						name={equipmentName}
						onValueChange={onFieldChange(equipmentName)}
						renderMenuItem={EquipmentItem}
						sectionOptionsKey="options"
						sections={sections}
						sectionTitleKey="title"
						selected={entry.equipmentId}
						useSectionList
						value={entry.equipmentId}
						valueKey="id"
						withCaret={true}
					/>
				</Col>
				<Col sm={canEditClassificationCodes && canSeeTimeAllocations ? 4 : 6}>
					<TimeIntervalDropdown
						fixed={false}
						label="Time"
						name={durationName}
						onChange={onFieldChange(durationName)}
					/>
				</Col>
				{
					canSeeTimeAllocations &&
					<Col className={bemElement('time-sheet-list__equipment-modal__time-split-list__row', 'allocation')} sm={7}>
						<Field
							component={Dropdown}
							id={allocatedWorkRequestName}
							label={'Allocate to Sub-job'}
							labelKey="jobCode"
							name={allocatedWorkRequestName}
							onChange={onFieldChange(allocatedWorkRequestName)}
							options={subJobs}
							value={entry.allocatedWorkRequestId}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				}
				<Col sm={1}>
					<div className={bemElement('time-sheet-list__equipment-modal__time-split-list__row', 'delete-button')}>
						<Tooltip message="Delete">
							<span className="icon-delete" onClick={onDeleteEntry}></span>
						</Tooltip>
					</div>

				</Col>
			</Row>

		</div>
	);
};

export default TimeSplitEntry;
