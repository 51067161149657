import * as React from 'react';
import { Field, FormErrorsWithArray, WrappedFieldArrayProps } from 'redux-form';
import { CellContext } from '@tanstack/react-table';
import { nanoid } from 'nanoid';

import PurchaseOrderItemCompletion from 'acceligent-shared/enums/purchaseOrderItemCompletion';
import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';

import { DEFAULT_EQUIPMENT_IMAGE, UNIQUE_ID_SIZE } from 'ab-constants/value';

import Dropdown from 'af-fields/Dropdown';
import Input from 'af-fields/Input';
import SimpleTableField, { SimpleTableRow } from 'af-fields/SimpleTable';
import SimpleTableControl from 'af-components/Controls/SimpleTable';
import ImageTag from 'af-components/Image';
import DropdownComponent from 'af-components/Controls/Dropdown';
import { FooterButton, SimpleTableProps } from 'af-components/Controls/SimpleTable/types';

import ItemOptionVM from 'ab-viewModels/item/itemOption.viewModel';

import { dollarFormatter } from 'af-utils/format.util';

import { PurchaseOrderFormModel, PurchaseOrderItemFormModel } from './formModel';
import styles from './styles.module.scss';
import { statusDropdownOptions } from './helpers';

export interface OwnProps {
	availableItems: ItemOptionVM[];
	vendorId: Nullable<number>;
	isReadOnly: boolean;
	setUnsavedChanges: () => void;
	errors: FormErrorsWithArray<PurchaseOrderFormModel, string>;
	initialized: boolean;
	change: (field: string, value: string | number) => void;
}

type Props = OwnProps & WrappedFieldArrayProps<PurchaseOrderItemFormModel>;

const renderItemOption = (item: ItemOptionVM) => (
	<>
		<ImageTag
			fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
			minSize={BlobStorageImageSizeContainer.SIZE_50X50}
			src={item.imageUrl}
			tryOriginal={true}
			tryRoot={true}
		/>
		<div className={styles['item-option']}>
			<span>{item.name} {item.partNumber ? `${item.partNumber}` : ''}</span>
			<span className={styles['item-option__details']}>
				{item.modelNumber}{item.vendorName ? ` | ${item.vendorName}` : ''}
			</span>
		</div>
	</>
);

const PurchaseOrderItems: React.FC<Props> = (props) => {
	const { fields, availableItems, vendorId, isReadOnly, setUnsavedChanges, errors, initialized, change } = props;

	const [showSelectItemDropdown, setShowSelectItemDropdown] = React.useState(false);

	const addItem = React.useCallback(() => {
		fields.push({
			id: nanoid(UNIQUE_ID_SIZE),
			itemId: null,
			itemName: '',
			itemNumber: '',
			itemsInvoiced: PurchaseOrderItemCompletion.NONE,
			itemsReceived: PurchaseOrderItemCompletion.NONE,
			priceEach: 0,
			quantity: 1,
			extendedPrice: 0,
		});
		setUnsavedChanges();
	}, [fields, setUnsavedChanges]);

	const selectItem = React.useCallback((item: ItemOptionVM) => {
		fields.push({
			itemId: item.id,
			itemName: item.name,
			itemNumber: item.modelNumber,
			itemsInvoiced: PurchaseOrderItemCompletion.NONE,
			itemsReceived: PurchaseOrderItemCompletion.NONE,
			priceEach: item.vendorId === vendorId ? item.price ?? 0 : 0,
			quantity: 1,
			extendedPrice: item.vendorId === vendorId ? item.price ?? 0 : 0,
			id: `${item.id}`, // this should be falsy when sending to BE, but if we put falsy here it breaks certain table functionalities, we make it falsy later
		});
		setUnsavedChanges();
		setShowSelectItemDropdown(false);
	}, [fields, setUnsavedChanges, vendorId]);

	const onCloseSelectItemDropdownClick = React.useCallback(() => setShowSelectItemDropdown(false), []);

	const renderSelectItemDropdown = React.useCallback(() => {
		return (
			<div className={styles['select-intentory-system']}>
				<div className={styles['select-intentory-system__dropdown']}>
					<DropdownComponent<ItemOptionVM>
						filterable={true}
						filterBy={['name', 'modelNumber', 'vendorName', 'partNumber']}
						id="item-dropdown"
						onValueChange={selectItem}
						options={availableItems}
						placeholder="Select From Inventory System"
						renderMenuItem={renderItemOption}
						valueKey="id"
						withCaret={true}
					/>
				</div>
				<span className="icon-close" onClick={onCloseSelectItemDropdownClick} />
			</div>
		);
	}, [selectItem, availableItems, onCloseSelectItemDropdownClick]);

	const recalculateExtendedPrice = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
		const fieldPath = event.target.name.split('.');
		const fieldName = fieldPath[1]; // either 'priceEach' or 'quantity'
		const fieldIndex = +(fieldPath[0].match(/\[(\d+)\]/)?.[1] as string);
		const field = fields.get(fieldIndex);

		if (fieldName === 'priceEach') {
			change(`${fieldPath[0]}.extendedPrice`, newValue * field.quantity);
		} else {
			change(`${fieldPath[0]}.extendedPrice`, newValue * field.priceEach);
		}

		setUnsavedChanges();
	}, [change, fields, setUnsavedChanges]);

	const getExtendedPrice = React.useCallback((fieldName: string) => {
		const fieldIndex = +(fieldName.match(/\[(\d+)\]/)?.[1] as string);
		return fields.get(fieldIndex)?.extendedPrice ?? 0;
	}, [fields]);

	const footerButtons = React.useMemo(() => {
		const resolvedFooterButtons = [{
			iconName: 'icon-plus',
			label: 'Add Item Manually',
			onClick: addItem,
		}];
		if (!showSelectItemDropdown) {
			resolvedFooterButtons.push({ iconName: 'icon-plus', label: 'Add Item From Inventory System', onClick: () => setShowSelectItemDropdown(true) });
		}
		return resolvedFooterButtons as FooterButton[];
	}, [addItem, showSelectItemDropdown]);

	const editModeColumns: SimpleTableProps<PurchaseOrderItemFormModel & SimpleTableRow>['columns'] = React.useMemo(() => [{
		id: 'itemNumber',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, string>) => {
			if (_cell.row.original.isInEditMode) {
				return (
					<Field
						component={Input}
						name={`${_cell.row.original.name}.itemNumber`}
					/>
				);
			}
			return _cell.getValue() ?? 'N/A';
		},
		header: 'Item No.',
		accessor: 'itemNumber',
		enableSorting: true,
	},
	{
		id: 'itemName',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, string>) => {
			if (_cell.row.original.isInEditMode) {
				return (
					<Field
						component={Input}
						name={`${_cell.row.original.name}.itemName`}
					/>
				);
			}
			return _cell.getValue() ?? 'N/A';
		},
		header: 'Item Name *',
		size: 300,
		accessor: 'itemName',
		enableSorting: true,
	},
	{
		id: 'quantity',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, number>) => {
			if (_cell.row.original.isInEditMode) {
				return (
					<Field
						component={Input}
						min={0}
						name={`${_cell.row.original.name}.quantity`}
						onChange={recalculateExtendedPrice}
						type="number"
					/>
				);
			}
			return _cell.getValue() ?? 'N/A';
		},
		header: 'Quantity *',
		accessor: 'quantity',
		enableSorting: false,
	},
	{
		id: 'priceEach',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, number>) => {
			if (_cell.row.original.isInEditMode) {
				return (
					<Field
						component={Input}
						min={0}
						name={`${_cell.row.original.name}.priceEach`}
						onChange={recalculateExtendedPrice}
						step={0.01}
						type="number"
					/>
				);
			}
			return dollarFormatter.format(_cell.getValue() ?? 0);
		},
		header: 'Price Each *',
		accessor: 'priceEach',
		enableSorting: false,
	},
	{
		id: 'extendedPrice',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, number>) => {
			return <div className={styles.right}>{dollarFormatter.format(getExtendedPrice(_cell.row.original.name))}</div>;
		},
		header: 'Extended Price',
		accessor: 'extendedPrice',
	},
	{
		id: 'itemReceived',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, number>) => {
			if (_cell.row.original.isInEditMode) {
				return (
					<Field
						className={styles['dropdown-white-background']}
						component={Dropdown}
						disabled={isReadOnly}
						labelKey="label"
						name={`${_cell.row.original.name}.itemsReceived`}
						onChange={setUnsavedChanges}
						options={statusDropdownOptions}
						valueKey="label"
						withCaret={true}
					/>
				);
			}
			return _cell.getValue() ?? null;
		},
		header: 'Item Received',
		accessor: 'itemsReceived',
		enableSorting: true,
	},
	{
		id: 'itemInvoiced',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel & SimpleTableRow, number>) => {
			if (_cell.row.original.isInEditMode) {
				return (
					<Field
						className={styles['dropdown-white-background']}
						component={Dropdown}
						disabled={isReadOnly}
						labelKey="label"
						name={`${_cell.row.original.name}.itemsInvoiced`}
						onChange={setUnsavedChanges}
						options={statusDropdownOptions}
						valueKey="label"
						withCaret={true}
					/>
				);
			}
			return _cell.getValue() ?? null;
		},
		header: 'Item Invoiced',
		accessor: 'itemsInvoiced',
		enableSorting: true,
		// We don't want to add recalculating actions because it will cause problems with field inputs
		// User will have to mouse-click the field again for every character input
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}], [isReadOnly, setUnsavedChanges]);

	const readonlyModeColumns: SimpleTableProps<PurchaseOrderItemFormModel>['columns'] = React.useMemo(() => [{
		id: 'itemNumber',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, string>) => {
			return _cell.getValue() ?? 'N/A';
		},
		header: 'Item No.',
		accessor: 'itemNumber',
		enableSorting: true,
	},
	{
		id: 'itemName',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, string>) => {
			return _cell.getValue() ?? 'N/A';
		},
		header: 'Item Name *',
		size: 300,
		accessor: 'itemName',
		enableSorting: true,
	},
	{
		id: 'quantity',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, number>) => {
			return _cell.getValue() ?? 'N/A';
		},
		header: 'Quantity *',
		accessor: 'quantity',
		enableSorting: false,
	},
	{
		id: 'extendedPrice',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, number>) => {
			return dollarFormatter.format(_cell.row.original.priceEach * _cell.row.original.quantity);
		},
		header: 'Extended Price',
		accessor: 'extendedPrice',
	},
	{
		id: 'priceEach',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, number>) => {
			return dollarFormatter.format(_cell.getValue() ?? 0);
		},
		header: 'Price Each *',
		accessor: 'priceEach',
		enableSorting: false,
	},
	{
		id: 'itemReceived',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, number>) => {
			return _cell.getValue() ?? null;
		},
		header: 'Item Received',
		accessor: 'itemsReceived',
		enableSorting: true,
	},
	{
		id: 'itemInvoiced',
		cell: (_cell: CellContext<PurchaseOrderItemFormModel, number>) => {
			return _cell.getValue() ?? null;
		},
		header: 'Item Invoiced',
		accessor: 'itemsInvoiced',
		enableSorting: true,
	}], []);

	if (isReadOnly) {
		return <SimpleTableControl columns={readonlyModeColumns} label="Items" rows={fields.getAll()} />;
	}

	return (
		<SimpleTableField
			allowEdit={true}
			columns={editModeColumns}
			emptyTableMessage='No items added. Press "+ Add" to add an item.'
			errors={errors.purchaseOrderItems}
			fields={fields}
			footerButtons={footerButtons}
			footerComponent={showSelectItemDropdown ? renderSelectItemDropdown : undefined}
			initialized={initialized}
		/>
	);
};

export default React.memo(PurchaseOrderItems);
