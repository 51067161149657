import { Dispatch, AnyAction } from 'redux';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import { DailyPerDiemTipViewModel } from 'ab-viewModels/dailyPerDiemTip.viewModel';

import { GetRootState } from 'af-reducers';

export function findLastFiveDailyPerDiemTips() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<DailyPerDiemTipViewModel[]>(API.V1.DAILY_PER_DIEM_TIP.LAST_FIVE);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function create(message: string, dueDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async (): Promise<void> => {
			await http.post<DailyPerDiemTipViewModel>(API.V1.DAILY_PER_DIEM_TIP.CREATE, { message, dueDate });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
