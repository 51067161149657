import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps, ResolveThunks } from 'react-redux';
import { compose } from 'redux';
import { Button } from 'react-bootstrap';
import { reduxForm, InjectedFormProps, getFormValues, formValueSelector } from 'redux-form';

import Priority from 'acceligent-shared/enums/priority';

import * as TimeUtils from 'acceligent-shared/utils/time';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import { RootState } from 'af-reducers';

import DetailsTab from 'af-components/SharedForms/Job/Details';
import JobUpsertFM, { SubjobFM } from 'af-components/SharedForms/Job/formModel';
import JobHazardAssessmentTab from 'af-components/SharedForms/Job/JobHazardAssessment';
import Breadcrumbs from 'af-components/Breadcrumbs';
import SubmitButton from 'af-components/SubmitButton';
import TabNavigation from 'af-components/TabNavigation';
import ConfirmationModal from 'af-components/ConfirmationModal';

import { BillingCodeFM } from 'af-components/SharedForms/Job/Details/Sections/CustomerSignatureAndBillingCodes/BillingCodes/BillingCodeArray';

import * as JobActions from 'af-actions/jobs';

import { useToggle } from 'af-utils/react.util';

import * as FORMS from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import { TABS } from '../helpers';
import styles from '../styles.module.scss';
import { isValidTextInput } from 'acceligent-shared/utils/text';

interface JobCustomLocationState {
	orgAlias: string;
	redirectUrl?: string;
	originUrl?: string;
	defaultDueDate?: Date;
	job?: JobUpsertFM;
	jobCode?: string;
	previousFormState?: JobUpsertFM;
	subjob?: SubjobFM;
}

type OwnProps = CustomRouteComponentProps<string, Record<string, unknown>, JobCustomLocationState>;

interface DispatchProps {
	createJob: typeof JobActions.createJob;
}

type FormOwnProps = ConnectedProps<typeof connector> & ResolveThunks<DispatchProps> & OwnProps;
type Props = FormOwnProps & InjectedFormProps<JobUpsertFM, FormOwnProps>;

const _isInvalidBillingCode = (billingCode: BillingCodeFM) => {
	const errors = JobUpsertFM.validateBillingCode(billingCode);
	return Object.keys(errors).length > 0;
};

const CreateJob: React.FC<Props> = (props) => {
	const {
		createJob,
		history,
		location: { state: { orgAlias, originUrl, defaultDueDate, jobCode, previousFormState, subjob } },
		companyName,
		change,
		formJobCode,
		title,
		customerCompanyName,
		priority,
		office,
		billingCodes,
		handleSubmit,
		jobHazardAssessmentStatus,
		submitting,
		isProject,
		subjobs,
		projectId,
		dueDate,
		currentFormValues,
		redirectUrl,
	} = props;

	const [activeTabId, setActiveTabId] = React.useState(0);
	const [availableSubjobs, setAvailableSubjobs] = React.useState<SubjobFM[]>([]);
	const [selectedSubjob, setSelectedSubjob] = React.useState<Nullable<SubjobFM>>(null);
	const [subjobSearchText, setSubjobSearchText] = React.useState<string>('');

	const {
		value: showTurnToProjectConfirmationModal,
		setToTrue: openTurnToProjectConfirmationModal,
		setToFalse: hideTurnToProjectConfirmationModal,
	} = useToggle(false);

	const {
		value: showAddSubjobConfirmationModal,
		setToTrue: openAddSubjobConfirmationModal,
		setToFalse: hideAddSubjobConfirmationModal,
	} = useToggle(false);

	const {
		value: showSubmitWithSubjobsConfirmationModal,
		setToTrue: openSubmitWithSubjobsConfirmationModal,
		setToFalse: hideSubmitWithSubjobsConfirmationModal,
	} = useToggle(false);

	const {
		value: hasAddedSujobs,
		setToTrue: setAddedSujobs,
	} = useToggle(false);

	const {
		value: turnToProjectDisabled,
		setToTrue: disableTurnToProject,
	} = useToggle(isProject);

	React.useEffect(() => {
		if (jobCode) {
			change('jobCode', jobCode);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const closeTurnToProjectConfirmationModal = React.useCallback(async () => {
		change('isProject', false);
		hideTurnToProjectConfirmationModal();
	}, [change, hideTurnToProjectConfirmationModal]);

	const closeAddSubjobConfirmationModal = React.useCallback(async () => {
		hideAddSubjobConfirmationModal();
	}, [hideAddSubjobConfirmationModal]);

	const addSubjob = React.useCallback(() => {
		setAddedSujobs();
		if (selectedSubjob) {
			change('subjobs', [...(subjobs ?? []), selectedSubjob]);
			setAvailableSubjobs([...availableSubjobs.filter((_sj) => _sj.id !== selectedSubjob.id)]);
			setSelectedSubjob(null);
		}
	}, [availableSubjobs, change, selectedSubjob, setAddedSujobs, subjobs]);

	const turnToProject = React.useCallback(() => {
		change('isProject', true);
	}, [change]);

	const goToJobForm = React.useCallback(() => {
		// redirects user to job form. when user fills the form and save it, he/she will be
		// redirected back to original job form with new subjob and all other changes

		history.push({
			pathname: CLIENT.COMPANY.JOBS.CREATE(orgAlias, companyName),
			state: {
				redirectUrl: CLIENT.COMPANY.JOBS.CREATE(orgAlias, companyName),
				defaultDueDate: TimeUtils.parseDate(dueDate),
				orgAlias,
				jobCode: subjobSearchText,
				previousFormState: currentFormValues,
			},
		});
	}, [companyName, currentFormValues, dueDate, history, orgAlias, subjobSearchText]);

	const handleSubjobSelect = React.useCallback((selectedOption: Nullable<SubjobFM>) => {
		setSelectedSubjob(selectedOption);
	}, []);

	const onTurnToProjectChange = React.useCallback((value: boolean) => {
		if (value) {
			openTurnToProjectConfirmationModal();
		}
	}, [openTurnToProjectConfirmationModal]);

	const onSubmit = React.useCallback(async (form: Partial<JobUpsertFM>) => {
		const job = await createJob(JobUpsertFM.toRM(form));
		if (redirectUrl) {
			if (!job) {
				throw new Error('Job not defined');
			}
			const formJob = new JobUpsertFM(job);
			if (redirectUrl?.includes('jobs/edit') || redirectUrl?.includes('jobs/create')) {
				history.push({
					pathname: redirectUrl,
					state: { subjob: SubjobFM.fromJobUpsertFM(formJob), defaultDueDate, orgAlias, previousFormState },
				});
			} else {
				history.push({ pathname: redirectUrl, state: { job: formJob, defaultDueDate, orgAlias } });
			}

		} else if (originUrl) {
			history.push(originUrl);
		} else {
			history.push(CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName));
		}
	}, [companyName, createJob, defaultDueDate, history, orgAlias, originUrl, previousFormState, redirectUrl]);

	const onCreateJob = React.useCallback(async (form: Partial<JobUpsertFM>) => {
		await onSubmit(form);
	}, [onSubmit]);

	const beforeCreateJob = React.useCallback(async (form: JobUpsertFM) => {
		if (!hasAddedSujobs) {
			await onCreateJob(form);
			return;
		} else {
			openSubmitWithSubjobsConfirmationModal();
		}
	}, [hasAddedSujobs, onCreateJob, openSubmitWithSubjobsConfirmationModal]);

	const onTabSelect = React.useCallback((tabId: number) => {
		setActiveTabId(tabId);
	}, []);

	const renderTabContent = React.useCallback(() => {

		switch (activeTabId) {
			case TABS[0].id: {
				return (
					<DetailsTab
						availableSubjobs={availableSubjobs}
						change={change}
						disableTurnToProject={disableTurnToProject}
						formName={FORMS.JOB_CREATE}
						goToJobForm={goToJobForm}
						handleSubjobSelect={handleSubjobSelect}
						isProject={isProject}
						onTurnToProjectChange={onTurnToProjectChange}
						openAddSubjobConfirmationModal={openAddSubjobConfirmationModal}
						orgAlias={orgAlias}
						projectId={projectId}
						redirectOnSubmit={!!redirectUrl}
						selectedSubjob={selectedSubjob}
						setAvailableSubjobs={setAvailableSubjobs}
						setSubjobSearchText={setSubjobSearchText}
						subjobs={subjobs}
						subjobSearchText={subjobSearchText}
						turnToProjectDisabled={turnToProjectDisabled}
					/>
				);
			}

			case TABS[1].id: {
				return <JobHazardAssessmentTab change={change} formName={FORMS.JOB_CREATE} />;
			}
		}
	}, [
		activeTabId,
		availableSubjobs,
		change,
		handleSubjobSelect,
		isProject,
		onTurnToProjectChange,
		orgAlias,
		projectId,
		redirectUrl,
		subjobs,
		goToJobForm,
		setSubjobSearchText,
		setAvailableSubjobs,
		openAddSubjobConfirmationModal,
		selectedSubjob,
		subjobSearchText,
		turnToProjectDisabled,
		disableTurnToProject,
	]);

	const onBackToListButtonClick = React.useCallback(() => {
		history.push(CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName));
	}, [companyName, history, orgAlias]);

	const areRequiredWorkRequestFieldsFilled = React.useMemo(() => {
		return (
			!!formJobCode
			&& !!title
			&& !!customerCompanyName
			&& !!priority
			&& !!office
			&& !billingCodes?.some(_isInvalidBillingCode)
		);
	}, [formJobCode, title, customerCompanyName, priority, office, billingCodes]);

	const renderTabLabel = React.useCallback((id: number) => {
		const areRequiredHazardAssessmentFieldsFilled = !!jobHazardAssessmentStatus;
		switch (id) {
			case TABS[0].id: {
				return (
					<div className={styles['job-form__tab']}>
						{!areRequiredWorkRequestFieldsFilled && <span className="icon-warning" />}
						Job
					</div>
				) as JSX.Element;
			}
			case TABS[1].id: {

				return (
					<div className={styles['job-form__tab']}>
						{!areRequiredHazardAssessmentFieldsFilled && <span className="icon-warning" />}
						Job Hazard Assessment
					</div>
				) as JSX.Element;
			}

			default: {
				return <></>;
			}
		}
	}, [jobHazardAssessmentStatus, areRequiredWorkRequestFieldsFilled]);

	const areRequiredFilled = (isValidTextInput(formJobCode)
		&& isValidTextInput(title)
		&& isValidTextInput(customerCompanyName)
		&& !!priority
		&& !!office
		&& !!jobHazardAssessmentStatus);

	React.useEffect(() => {
		if (previousFormState && !(redirectUrl?.includes('jobs/create') || redirectUrl?.includes('jobs/edit'))) {
			// Directly handle the special case for 'subjobs'
			if (previousFormState.hasOwnProperty('subjobs')) {
				const updatedSubjobs = subjob ? [...(previousFormState.subjobs ?? []), subjob] : previousFormState.subjobs;
				change('subjobs', updatedSubjobs);
			}

			// Iterate over and update the rest of the form state
			Object.entries(previousFormState).forEach(([key, value]) => {
				if (key !== 'subjobs') { // Skip 'subjobs' since it's already handled
					change(key, value);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Button className={styles['job-form__back-to-list']} onClick={onBackToListButtonClick} variant="info">
				<span className="icon-left" />
				Back to List
			</Button>
			<Breadcrumbs
				items={[
					{ label: 'Jobs', url: CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName) },
					{ label: 'New Job' },
				]}
			/>
			<div className={styles['job-form__submit-section']}>
				<div className={styles['job-form__submit-section__hint']}>
					Please Fill out both Job and Job Hazard Assessment forms to submit.
				</div>
				<SubmitButton
					disabled={!areRequiredFilled}
					label="Submit"
					onClick={handleSubmit(beforeCreateJob)}
					reduxFormSubmitting={submitting}
				/>
			</div>
			<TabNavigation
				active={activeTabId}
				navigationClassName={styles['job-form__tabs-navigation']}
				onClick={onTabSelect}
				renderLabel={renderTabLabel}
				tabs={TABS}
			/>
			{renderTabContent()}
			<ConfirmationModal
				body="Convert this Job to Project. This action is irreversible. Continue?"
				closeModal={closeTurnToProjectConfirmationModal}
				confirmAction={turnToProject}
				confirmText="Continue"
				modalStyle="grey-warning"
				showModal={showTurnToProjectConfirmationModal}
				title={'Convert Job to Project?'}
			/>
			<ConfirmationModal
				body={`Associate ${selectedSubjob?.jobCode} with ${jobCode}. Continue?`}
				closeModal={closeAddSubjobConfirmationModal}
				confirmAction={addSubjob}
				confirmText="Continue"
				modalStyle="grey-warning"
				showModal={showAddSubjobConfirmationModal}
				title={`Associate ${selectedSubjob?.jobCode} with ${jobCode}?`}
			/>
			<ConfirmationModal
				body={'You have associated Sub-jobs with this Project. After submiting the form Sub-jobs cannot be detached from the Project. Submit?'}
				closeModal={hideSubmitWithSubjobsConfirmationModal}
				confirmAction={handleSubmit(onSubmit)}
				confirmText="Submit"
				modalStyle="grey-warning"
				showModal={showSubmitWithSubjobsConfirmationModal}
				title={'Submit project with Sub-jobs?'}
			/>
		</>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { user: { companyData, userData } } = state;
	const formState = getFormValues(FORMS.JOB_CREATE);
	const formSelector = formValueSelector(FORMS.JOB_CREATE);
	const { location: { state: { defaultDueDate = new Date(), redirectUrl } } } = ownProps;
	const currentFormValues = getFormValues(FORMS.JOB_CREATE)(state) as JobUpsertFM;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const {
		jobCode,
		title,
		customerCompanyName,
		priority,
		office,
		jobHazardAssessmentStatus,
		isProject,
		subjobs,
		id: workRequestId,
		projectId,
		billingCodes,
	} = formState(state) as JobUpsertFM ?? ({} as Partial<JobUpsertFM>);

	/** DB_DATE_ONLY "YYYY-MM-DD" */
	const dueDate: string = formSelector(state, 'startDate');

	return {
		companyName: companyData.name,
		formJobCode: jobCode,
		title,
		customerCompanyName,
		priority,
		office,
		jobHazardAssessmentStatus,
		billingCodes,
		isProject,
		subjobs,
		workRequestId,
		projectId,
		dueDate: dueDate ?? TimeUtils.formatDate(defaultDueDate, TimeFormatEnum.DB_DATE_ONLY, TimeFormatEnum.JS_TIME),
		currentFormValues,
		redirectUrl,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		createJob: JobActions.createJob,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	reduxForm<JobUpsertFM, FormOwnProps>({
		form: FORMS.JOB_CREATE,
		validate: JobUpsertFM.validate,
		warn: JobUpsertFM.warn,
		initialValues: {
			priority: Priority.MEDIUM,
			estimateTotal: 0,
			numberOfEmployees: 0,
		},
	}),
	connector
);
export default enhance(CreateJob);
